import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas";
import { infoUser } from "./infoUser/reducer";
import { login } from "./login/reducer";

const reducers = combineReducers({
  infoUser,
  login,
});

const sagaMiddleware = createSagaMiddleware();
let composedEnchancers;
if (process.env.REACT_APP_NODE_ENV !== "production") {
  composedEnchancers = compose(
    applyMiddleware(sagaMiddleware),
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
} else {
  composedEnchancers = compose(applyMiddleware(sagaMiddleware));
}
const store = createStore(reducers, composedEnchancers);
sagaMiddleware.run(rootSaga);
export default store;

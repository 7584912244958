const actionLogin = (payload) => {
  return {
    type: "LOGIN",
    payload: payload,
  };
};

const actionVerifyToken = () => {
  return {
    type: "VERIFY_TOKEN",
  };
};

const loginSuccess = (payload) => {
  return {
    type: "LOGIN_SUCCESS",
    payload: payload,
  };
};

const loginFailed = (payload) => {
  return {
    type: "LOGIN_FAILED",
    payload: payload,
  };
};

const actionLogout = () => {
  return {
    type: "LOGOUT",
    payload: { isAuthenticated: false },
  };
};

export {
  actionLogin,
  actionLogout,
  loginFailed,
  loginSuccess,
  actionVerifyToken,
};

import { sendPasswordResetEmail } from "firebase/auth";
import { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { authentication } from "../../utilities/firebase/firebaseConfig";
import "./ForgotPassword.scss";

const ForgotPassword = () => {
  const [email, setImail] = useState("");

  const triggerResetEmail = async () => {
    await sendPasswordResetEmail(authentication, email);
  };

  return (
    <Container className="container__ForgotPassword p-5" fluid>
      <Row className="pt-3">
        <p className="Title">¿Olvidaste tu contraseña?</p>
      </Row>
      <Row className="message pt-5">
        <>
          <b>¡No te preocupes! </b>
          <p>
            Simplemente completa tu correo electrónico y te enviaremos un enlace
            para restablecer su contraseña.
          </p>
        </>
      </Row>
      <Row className="pt-4">
        <input
          placeholder="Email"
          className="input__formLoginAndRegister"
          value={email}
          onChange={(e) => {
            setImail(e.target.value);
          }}
        ></input>
      </Row>
      <Row className="justify-content-center pt-4 pb-3">
        <Col xs={10}>
          <Button
            className="button_loginAndRegister"
            onClick={triggerResetEmail}
          >
            Enviar
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default ForgotPassword;

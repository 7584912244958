import axios from "axios";

export const GetAllTopics = async () => {
  return axios.get("https://us-central1-sitlen.cloudfunctions.net/app/temas", {
    headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
  });
};

export const topcisMapImageUrl = [
  {
    imageLogo: "imagenFisica",
    title: "Física",
    url: "fisica",
  },
  {
    imageLogo: "imagenGeometria",
    title: "Geometría",
    url: "geometria",
  },
  {
    imageLogo: "imagenMatematicas",
    title: "Matemáticas",
    url: "matematicas",
  },
  {
    imageLogo: "imagenQuimica",
    title: "Química",
    url: "quimica",
  },
  {
    imageLogo: "imagenHistoria",
    title: "Historia",
    url: "historia",
  },
  {
    imageLogo: "imagenBiologia",
    title: "Biología",
    url: "biologia",
  },
];

import { useEffect, useRef, useState } from "react";
import "./GoingPomodoro.scss";
import Icon from "../../atoms/Icon/Icon";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const GoingPomodoro = () => {
  const [pomodoroDuration, setPomodoroDuration] = useState(25 * 60);
  const [paused, setPaused] = useState(true);
  const [fullScreen, setFullScreen] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!paused) {
      const timer = setInterval(() => {
        setPomodoroDuration((pomodoroDuration) => pomodoroDuration - 1);
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [paused]);

  const timeFormatter = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secondsRest = seconds % 60;

    const formattedTime = `${minutes}:${
      secondsRest < 10 ? "0" : ""
    }${secondsRest}`;

    return formattedTime;
  };

  const handlePauseOrPlayCounter = () => {
    setPaused(!paused);
  };

  const handleChangeScream = () => {
    const elemento = document.documentElement;
    if (fullScreen) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    } else {
      if (elemento.requestFullscreen) {
        elemento.requestFullscreen();
      } else if (elemento.mozRequestFullScreen) {
        // Firefox
        elemento.mozRequestFullScreen();
      } else if (elemento.webkitRequestFullscreen) {
        // Chrome, Safari y Opera
        elemento.webkitRequestFullscreen();
      } else if (elemento.msRequestFullscreen) {
        // IE/Edge
        elemento.msRequestFullscreen();
      }
    }
    setFullScreen(!fullScreen);
  };

  const handleChangeAddPomodoro = () => {
    history.push(`?site=nuevoPomodoro`);
    if (fullScreen) handleChangeScream();
  };

  return (
    <div className={`going-pomodoro ${fullScreen ? "full-screen" : ""}`}>
      <h1>{timeFormatter(pomodoroDuration)}</h1>
      <button
        onClick={handlePauseOrPlayCounter}
        className="going-pomodoro__icon-button normal-screen"
      >
        <Icon name={paused ? "play_Icon" : "pause_Icon"} size={30} />
      </button>
      <div className="going-pomodoro__container-actions">
        <button
          onClick={handleChangeScream}
          className="going-pomodoro__icon-button"
        >
          <Icon
            name={fullScreen ? "unFull_Screen_Icon" : "full_Screen_Icon"}
            size={60}
          />
        </button>
        <button
          onClick={handlePauseOrPlayCounter}
          className="going-pomodoro__icon-button full-screen"
        >
          <Icon name={paused ? "play_Icon" : "pause_Icon"} size={50} />
        </button>
        <button
          onClick={handleChangeAddPomodoro}
          className="going-pomodoro__icon-button"
        >
          <Icon name={"edit_Icon"} size={60} />
        </button>
      </div>
    </div>
  );
};

export default GoingPomodoro;

import { Col, Container, Row } from "react-bootstrap";
import ContainerVideo from "../../atoms/containerVideo/ContainerVideo";
import BasicLayout from "../../templates/layout/BasicLayout";
import TitleSection from "../../atoms/TitleSection/TitleSection";
import "./VideoSection.scss";

const VideoSection = () => {
  return (
    <Container
      className="container__VideoSection d-flex justify-content-center"
      fluid
    >
      <BasicLayout>
        <div className="container__Video">
          <Row className="pt-5">
            <Col className="pt-5 mb-5">
              <TitleSection
                classname={"TitleExtra"}
                colorTag={"#153039"}
                positionTag={"left"}
                title={"Extra"}
              />
            </Col>
          </Row>
          <div>
            <ContainerVideo />
          </div>
        </div>
      </BasicLayout>
    </Container>
  );
};

export default VideoSection;

const initialState = {
  isAuthenticated: localStorage.getItem("Token") ? true : false,
  message: null,
};

export const login = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
      };
    case "LOGIN_FAILED":
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        message: action.payload.message,
      };
    case "LOGOUT":
      return {
        isAuthenticated: action.payload.isAuthenticated,
      };
    default:
      return state;
  }
};

import './QuickCalculationForms.scss'
import { useEffect, useState } from 'react'
import { RevisionOfTextEntries } from '../../atoms/revisionOfTextEntries/RevisionOfTextEntries'
import ButtonList from '../../atoms/buttonList/ButtonList'
import InputsList from '../../atoms/inputList/InputList'
import {
  NumberChecker,
  NumberCheckerWhitSymbols,
} from '../../atoms/revisionOfNumberEntries/revisionOfNumberEntries'
import ModalAlertMessage from '../../atoms/modalAlertMessage/ModalAlertMessage'
import NoteNeededCalculation from '../../atoms/noteNeededCalculation/NoteNeededCalculation'
import { globalDataAndFunctions } from '../.././molecules/interactiveContainerQuickCalculation/InteractiveContainerQuickCalculation'
import { useContext } from 'react'

const QuickCalculationForms = () => {
  //Se obtiene la función handleChangeCurrentmode del contexto global
  const { changeMode, setData, dataToUpdate, setDataToUpdate } = useContext(
    globalDataAndFunctions
  )

  //Controla el estado actual de toda la seccion del formulario
  const [currentFormMode, setCurrentFormMode] = useState('')

  //Controla el estado de los mensajes que se muestran en el modal
  const [messageShowModal, setMessageShowModal] = useState('')
  const [showModal, setShowModal] = useState(false)

  //Arreglos donde se almacenaran las notas con la informacio para la base de datos
  const [Arraynotas, setArrayNotas] = useState([])
  //Arreglo donde se guardara la informacion para el renderizado de las notas que se van creando
  const [viewArrayNotas, setViewArrayNotas] = useState([])

  //Estados donde se almcena toda la informacion del formulario
  const [subjectName, setSubjectName] = useState('')
  const [noteMax, setNoteMax] = useState('')
  const [noteMin, setNoteMin] = useState(0.0)
  const [noteAprobatory, setNoteAprobatory] = useState('')
  const [nameNota, setNameNota] = useState('')
  const [newNote, setnewNote] = useState('')
  const [percentage, setPercentage] = useState('')

  //Estado donde se almacenara la nota necesaria para aprobar
  const [NoteNeeded, setNoteNeeded] = useState(0.0)

  //Estado donde se almacenara el id de la nota que se esta editando
  const [editId, setEditId] = useState()
  const [editMode, setEditMode] = useState(false)

  //Estado para el uso de UseEffect para la edicion de notas
  const [test, setTest] = useState(false)
  const [updateButtonNewNote, setUpdateButtonNewNote] = useState()
  const [updateButtonInfoNote, setUpdateButtonInfoNote] = useState()
  const [activatedupdateButtonInfoNote, setActivatedupdateButtonInfoNote] =
    useState()
  const [historyArray, setHistoryArray] = useState([])

  //**Funciones */

  //!Trae la informacion del historial para ser actualizada
  useEffect(() => {
    if (dataToUpdate) {
      if (dataToUpdate.message === undefined) {
        setArrayNotas(dataToUpdate.Notas)
        const viewArrayNotas = dataToUpdate.Notas.map((nota, index) => ({
          className: 'ButtonInteractiveContainer FormButtonQuickCalculation',
          onClick: () => {
            geInfoNote(index, dataToUpdate.Notas[index])
          },
          children: nota.nombre,
        }))
        setViewArrayNotas(viewArrayNotas)
        if (dataToUpdate.nombre) {
          setSubjectName(dataToUpdate.nombre)
        }
        if (dataToUpdate.notaAprobatoria) {
          setNoteAprobatory(dataToUpdate.notaAprobatoria)
        }
        if (dataToUpdate.notaMinima) {
          setNoteMin(dataToUpdate.notaMinima)
        }
        if (dataToUpdate.notaMaxima) {
          setNoteMax(dataToUpdate.notaMaxima)
        }
      }
    }
  }, [dataToUpdate])

  //!Recolecta la informacion antes de cambiar de pagina
  const organizeInformation = () => {
    const NoteNeeded = NoteNeededCalculation(
      Arraynotas,
      noteAprobatory,
      setNoteNeeded
    )
    setData({
      subjectName,
      Arraynotas,
      NoteNeeded,
      noteMax,
      noteMin,
      noteAprobatory,
    })
  }

  const formEntryCheckerInAddNote = () => {
    if (
      subjectName.trim() !== '' &&
      nameNota.trim() !== '' &&
      newNote !== '' &&
      percentage !== '' &&
      percentage !== '%' &&
      checkPercentage()
    ) {
      return true
    } else {
      return false
    }
  }

  //!Setea el estado actual del formulario para viajar entre las opciones
  const handleChangeCurrentFormMode = value => {
    setCurrentFormMode(value)
  }

  //!Limpia los inputs del formulario
  const cleanInputs = () => {
    setNameNota('')
    setnewNote('')
    setPercentage('')
  }
  //!Muestra los mensajes de alerta
  const showAlertMessage = message => {
    setMessageShowModal(message)
    setShowModal(true)
  }

  //!Verifica que no se supere el 100% de la nota
  const checkPercentage = () => {
    //?Verifica que al añadir la nota no se pase del 100% de porcentaje
    const percentageNow = Arraynotas.map(
      sumaPorcentajes => sumaPorcentajes.porcentaje
    ).reduce((a, b) => a + b, 0)
    const percentageTotal =
      percentageNow + parseFloat(percentage.replace('%', ''))
    if (percentageTotal >= 100) {
      return false
    } else {
      return true
    }
  }

  //!VERIFICA QUE LOS CAMPOS ESTEN COMPLETOS ANTES DE HACER ALGUN CAMBIO ---> LLAMA A LA FUNCION PARA CREAR UN NUEVO CAMPO(BOTON) DE NOTA
  const checkFormValidity = mode => {
    //Primera entrada del usuario, donde solo debe poder acceder a la metodologia
    if (mode === 'metodology' && currentFormMode === '') {
      return true
    }
    //Si el usuario quiere cambiar de metodologia a metodologia
    else if (currentFormMode === 'metodology' && mode === 'metodology') {
      return true
    }
    //Si el usuario quiere cambiar de metodologia a añadir nota
    else if (currentFormMode === 'metodology' && mode === 'newNote') {
      if (
        subjectName.trim() !== '' &&
        noteMax !== '' &&
        noteMin !== '' &&
        noteAprobatory !== ''
      ) {
        if (noteMin < noteAprobatory && noteAprobatory <= noteMax) {
          //!Se establece por defecto el modo de edicion como falso para evitar que si el usuairio al estar en metodologia consulto alguna nota ya creada esta se edite al darle en añadir nota
          setEditMode(false)
          // console.log('modo edicion desactivado')
          //Si viewArrayNotas esta vacio, crea una nota "temporal" para poder añadir notas
          if (viewArrayNotas.length === 0) {
            createTemporalNote([])
          } else {
            createTemporalNote(viewArrayNotas)
          }
          return true
        } else {
          return 'Error en metodologia'
        }
      }
    }
    //Si el usuario quiere cambiar de añadir nota a metodologia
    else if (currentFormMode === 'newNote' && mode === 'metodology') {
      if (formEntryCheckerInAddNote()) {
        if (editMode) {
          updateNotes()
          //Limpia el id para que este no interfiera con los otros botones de actualizacion de id
          setEditId()
          setEditMode(false)
          // console.log('modo edicion desactivado')
          return 'editado'
        } else {
          addNotes()
          return true
        }
      } else {
        //Borrar la nota que se esta creando si no se completa
        viewArrayNotas.pop()
        return true
      }
    }
    //Si el usuario quiere cambiar de añadir nota a añadir nota
    else if (currentFormMode === 'newNote' && mode === 'newNote') {
      if (formEntryCheckerInAddNote()) {
        if (editMode) {
          updateNotes()
          //Limpia el id para que este no interfiera con los otros botones de actualizacion de id
          setEditId()
          setEditMode(false)
          // console.log('modo edicion desactivado')
          return 'editado'
        } else {
          const actualviewArrayNotas = addNotes()
          createTemporalNote(actualviewArrayNotas)
          return true
        }
      }
    }
    //Si el usuario quiere cambiar de añadir nota a realizar el calculo
    else if (mode === 'performQuickCalculation') {
      if (formEntryCheckerInAddNote() && editMode === false) {
        const actualviewArrayNotas = addNotes()
        createTemporalNote(actualviewArrayNotas)
        setTest(true)
        return true
      }
      // else {
      //   showAlertMessage('Por favor, completa todos los campos requeridos.')
      // }
    }
    return false
  }

  useEffect(() => {
    //?Añade a la lista de notas la nota que este en los campos del formulario
    organizeInformation()
    if (Arraynotas.length > 0 && test) {
      // console.log('Arraynotas', Arraynotas)
      changeMode('result')
    }
  }, [Arraynotas, test])

  //!Llama a la funcion de verificacion para ver si esta aprueba ir a otra seccion del formulario
  const handleButtonClick = mode => {
    //Al hacer una edicion, no se necesita hacer un cambio de seccion, solo se necesita guardar la informacion
    const check = checkFormValidity(mode)
    if (check === 'editado') {
      return ':D'
    } 
    //Logica convencional para cambiar de seccion
    else if (check === "Error en metodologia") {
      showAlertMessage(
        'Verifica que la nota mínima sea menor a la nota aprobatoria y que la nota aprobatoria sea menor o igual a la nota máxima.'
      )
    } else if(check){
      handleChangeCurrentFormMode(mode)
    }else{
      //Se verifica dentro de la funcion si la suma de los porcentajes no supera el 100% para no añadir mas cuadros, y se verifica fuera para saber que mensaje mostrar
      if (!checkPercentage()) {
        showAlertMessage('La suma de los porcentajes no puede superar el 100%')
      } else {
        showAlertMessage('Por favor, completa todos los campos requeridos.')
      }

    }
  }

  //!Actauliza el nombre de la nota "temporal" que se esta creando
  const updateTemporalName = name => {
    viewArrayNotas[viewArrayNotas.length - 1].children = name
  }

  //!Crea un nuevo boton de nota "temporal"
  const createTemporalNote = array => {
    //Limpiar los campos de la nota anterior
    cleanInputs()
    const defaultName = 'Nueva nota'
    const newArrayViewNotas = [
      ...array,
      {
        className: 'ButtonInteractiveContainer FormButtonQuickCalculation',
        //Guarda la informacion que se haya editado y limpia los impust
        onClick: () => {
          // Usar la marca de tiempo actual como valor para updateButtonNewNote
          setUpdateButtonNewNote(new Date().getTime())
        },
        children: defaultName,
      },
    ]
    setViewArrayNotas(newArrayViewNotas)
  }

  //!Actualizar las notas desde el boton "temporal"
  useEffect(() => {
    if (nameNota.trim() !== '' && newNote !== '' && percentage !== '') {
      updateNotes()
      setEditMode(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateButtonNewNote])

  //!Actualiza la informacion de la nota siempre y cuando se este en modo edicion y este definido el id de la nota
  const updateNotes = () => {
    if (editId === undefined) {
      return
    } else {
      const ArrayNotasUpdate = Arraynotas
      ArrayNotasUpdate[editId] = {
        nombre: nameNota,
        nota: parseFloat(newNote),
        porcentaje: parseFloat(percentage.replace('%', '')),
        id: editId,
      }
      setArrayNotas(ArrayNotasUpdate)

      const ArrayViewNotasUpdate = viewArrayNotas
      ArrayViewNotasUpdate[editId] = {
        className: 'ButtonInteractiveContainer FormButtonQuickCalculation',
        onClick: () => {
          geInfoNote(editId, ArrayNotasUpdate[editId])
        },
        children: nameNota,
      }
      setViewArrayNotas(ArrayViewNotasUpdate)
      cleanInputs()
    }
  }

  //!Añade las notas al array de notas para el endpoint y a la vista
  const addNotes = () => {
    //Añade las notas al array de notas para el endpoint
    const newArrayNotas = [
      ...Arraynotas,
      {
        nombre: nameNota,
        nota: parseFloat(newNote),
        porcentaje: parseFloat(percentage.replace('%', '')),
        id: Arraynotas.length,
      },
    ]
    setArrayNotas(newArrayNotas)

    //Añade las notas a la vista -> Cada Boton guarda su informacion para poder ser editada
    let newArrayViewNotas = [...viewArrayNotas]
    newArrayViewNotas[newArrayViewNotas.length - 1] = {
      className: 'ButtonInteractiveContainer FormButtonQuickCalculation',
      onClick: () => {
        geInfoNote(
          viewArrayNotas.length - 1,
          newArrayNotas[viewArrayNotas.length - 1]
        )
      },
      children: nameNota,
    }
    setViewArrayNotas(newArrayViewNotas)

    return newArrayViewNotas
  }

  //!Actualizar la nota desde los botones con la informacion de la nota
  useEffect(() => {
    // console.log("editId -> Stado",editId)
    // console.log("idNote -> Parametro",updateButtonInfoNote)
    //Evita que se puedan setear las notas si los campos estan vacions (solucion de BUG)
    if (editId !== undefined && editId !== updateButtonInfoNote) {
      // console.log('se puede editar')
      if (nameNota.trim() !== '' && newNote !== '' && percentage !== '') {
        updateNotes()
      }
    }
    //Se trae la informacion para ser mostrada
    setNameNota(historyArray.nombre)
    setnewNote(historyArray.nota)
    setPercentage(`${historyArray.porcentaje}%`)
    setEditId(updateButtonInfoNote)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateButtonInfoNote, activatedupdateButtonInfoNote])

  //!Obtiene la informacion de la nota que se quiere revisar o editar
  const geInfoNote = (idNote, array) => {
    //Manejo de la actualizacion al dar click en una de las notas ya creadas
    setUpdateButtonInfoNote(idNote)
    setActivatedupdateButtonInfoNote(new Date().getTime())
    //Se actualiza la informacion de la nota que se quiere revisar
    setHistoryArray(array)
    //Activa el modo de edicion cada que se traigan las notas, verificando siempre que se este en el modo correcto
    setEditMode(true)
    // console.log('modo edicion activado')
  }

  //**RECURSOS-LOGICA DE RECURSOS*/
  //!Botones de metodologia y añadir nota
  let buttons = [
    {
      className: 'ButtonInteractiveContainer FormButtonQuickCalculation',
      onClick: () => {
        handleButtonClick('metodology')
      },
      children: 'Métodologia',
    },
    {
      className: 'ButtonInteractiveContainer FormButtonQuickCalculation',
      onClick: () => {
        handleButtonClick('newNote')
      },
      children: 'Añadir nota',
    },
  ]
  //!Inputs de la metodologia
  const InputsMetodology = [
    {
      classNameTitle: 'title__note',
      nameInpup: 'Nota máxima',
      type: 'text',
      value: noteMax,
      onChange: ev => NumberChecker(ev.target.value, setNoteMax),
      classNameInput: 'title__note Inputs',
    },
    {
      classNameTitle: 'title__note',
      nameInpup: 'Nota mínima',
      type: 'text',
      value: noteMin,
      onChange: ev => NumberChecker(ev.target.value, setNoteMin),
      classNameInput: 'title__note Inputs',
    },
    {
      classNameTitle: 'title__note',
      nameInpup: 'Nota aprobatoria',
      type: 'text',
      value: noteAprobatory,
      onChange: ev => NumberChecker(ev.target.value, setNoteAprobatory),
      classNameInput: 'title__note Inputs',
    },
  ]
  //!Inputs de la nueva nota
  const inputsNewNote = [
    {
      classNameTitle: 'title__note',
      nameInpup: 'Nombre',
      type: 'text',
      value: nameNota,
      onChange: ev => {
        RevisionOfTextEntries(ev.target.value, 'textWithnumbers', setNameNota)
        //Evitamos que cambie el nombre de la nota si esta en modo edicion, ya que es lo unico que afecta este modo
        if (!editMode) {
          updateTemporalName(ev.target.value)
        }
      },
      classNameInput: 'title__note Inputs',
    },
    {
      classNameTitle: 'title__note',
      nameInpup: 'Nota',
      type: 'text',
      value: newNote,
      onChange: ev => NumberChecker(ev.target.value, setnewNote),
      classNameInput: 'title__note Inputs',
    },
    {
      classNameTitle: 'title__note',
      nameInpup: 'Porcentaje',
      type: 'text',
      value: percentage,
      onChange: ev => {
        // Remueve el símbolo de porcentaje antes de verificar el número
        const value = ev.target.value.replace('%', '')
        NumberCheckerWhitSymbols(value, setPercentage, '%')
      },
      classNameInput: 'title__note Inputs',
    },
  ]

  return (
    <>
      <ModalAlertMessage
        showModal={showModal}
        setShow={setShowModal}
        message={messageShowModal}
      />
      <form
        onSubmit={ev => {
          ev.preventDefault()
          //?Verifica si el usuario ya ha creado notas y si el nombre de la materia esta lleno
          if (
            Arraynotas.length > 0 &&
            subjectName.trim() !== '' &&
            nameNota.trim() === '' &&
            newNote === '' &&
            percentage === ''
          ) {
            changeMode('result')
          } else {
            //?Ejecuta la logica para seguir a la siguiente pagina
            handleButtonClick('performQuickCalculation')
          }
        }}
      >
        <input
          placeholder='Nombre De Tu Asignatura'
          autoComplete='off'
          value={subjectName}
          onChange={ev =>
            RevisionOfTextEntries(ev.target.value, 'textOnly', setSubjectName)
          }
          className='subjecteInput'
          required
        />

        <div className='interactionContainer'>
          <div className='interactionContainer__functionSelector'>
            <ButtonList buttons={buttons} />
            <ButtonList buttons={viewArrayNotas} />
          </div>

          {currentFormMode === 'metodology' && (
            <div className='interactionContainer__noteInputs'>
              <InputsList inputs={InputsMetodology} />
            </div>
          )}

          {currentFormMode === 'newNote' && (
            <div className='interactionContainer__noteInputs'>
              <InputsList inputs={inputsNewNote} />
            </div>
          )}
        </div>

        {currentFormMode === 'newNote' && (
          <div className='areaForTheInteractiveButton'>
            <button type='submit' className='submitButton'>
              Realizar calculo rápido
            </button>
          </div>
        )}
      </form>
    </>
  )
}

export default QuickCalculationForms

import { useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { topcisMapImageUrl } from "../../utilities/tipics";
import ModalComingSoon from "../modalComingSoon/ModalComingSoon";
import "./SubjectLetterProfile.scss";

const SubjectLetterProfile = ({ item }) => {
  const [showModal, setShowModal] = useState(false);
  const infoFilter = topcisMapImageUrl.filter(
    (el) => el.title === item.Nombre
  )[0];

  return (
    <>
      <ModalComingSoon showModal={showModal} setShow={setShowModal} />
      <Container className="container__SubjectLetterProfile">
        <Row>
          <Col className="d-flex justify-content-center">
            <Image
              src={require(`../../app/img/${infoFilter.imageLogo}.png`)}
              height={"200px"}
              alt={item.Nombre}
            />
          </Col>
        </Row>
        <Row className="d-flex align-items-center justify-content-center">
          <Col>
            <h5>{item.Nombre}</h5>
          </Col>
          <Col className="d-flex justify-content-end">
            {item.Produccion ? (
              <a href={`materia/${infoFilter.url}`}>
                <u style={{ cursor: "pointer" }}>Ir a materia</u>
              </a>
            ) : (
              <a
                onClick={(e) => {
                  setShowModal(true);
                }}
              >
                <u style={{ cursor: "pointer" }}>Ir a materia</u>
              </a>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SubjectLetterProfile;

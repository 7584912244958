import { Col, Container, Image, Row } from "react-bootstrap";
import FormLoginAndRegister from "../../organisms/formLoginAndRegister/FormLoginAndRegister";
import "./LoginAndRegister.scss";

const LoginAndRegister = () => {
  return (
    <Container className="container__LoginAndRegister" fluid>
      <Row className="container__ChildrenLoginAndRegister justify-content-space-between mx-0 mx-lg-5">
        <Col
          className="container__ImageLogin d-none d-lg-flex justify-content-center align-content-center px-5"
          lg={7}
          xl={7}
        >
          <Image
            src={require("../../app/img/imageLogin.png")}
            alt="ImagenComputadorLog"
          />
        </Col>
        <Col xs={12} lg={5} xl={5} className="px-0  px-xl-5">
          <FormLoginAndRegister />
        </Col>
      </Row>
    </Container>
  );
};

export default LoginAndRegister;

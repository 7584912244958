import { put, call } from "redux-saga/effects";
import { loginSuccess, loginFailed } from "./actions";
import { addInfoUser } from "../infoUser/actions";
import axios from "axios";

export function* loginSaga(action) {
  try {
    const auxResult = yield call(
      axios.get,
      "https://us-central1-sitlen.cloudfunctions.net/app/login/token",
      {
        headers: { Authorization: `Bearer ${action.payload.token}` },
      }
    );
    const result = auxResult.data.Message;
    const infoUserAux = {
      token: action.payload.token,
      _id: result.uid,
      name: result.displayName ? result.displayName : result.email,
      email: result.email,
      pictureURL: result.photoURL,
    };
    console.log("Info user", result, auxResult, infoUserAux);
    yield put(
      loginSuccess({
        isAuthenticated: true,
      })
    );
    yield put(addInfoUser(infoUserAux));
    window.location.href = "/home";
  } catch (error) {
    yield put(
      loginFailed({
        isAuthenticated: false,
        message: "No se pudo autenticar",
      })
    );
  }
}

export function* verifyTokenSaga() {
  try {
    const auxResult = yield call(
      axios.get,
      "https://us-central1-sitlen.cloudfunctions.net/app/login/token",
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
      }
    );
    const result = auxResult.data.Message;
    const infoUserAux = {
      token: localStorage.getItem("Token"),
      _id: result.uid,
      name: result.displayName ? result.displayName : result.email,
      email: result.email,
      pictureURL: result.photoURL,
    };
    yield put(
      loginSuccess({
        isAuthenticated: true,
      })
    );
    yield put(addInfoUser(infoUserAux));
  } catch (error) {
    yield put(
      loginFailed({
        isAuthenticated: false,
        message: "No se pudo autenticar",
      })
    );
  }
}

import "./TitleSection.scss";

const TitleSection = ({ title, positionTag, classname, colorTag, accion }) => {
  return (
    <div className="TitleSection__externalContainer">
      {positionTag === "left" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="55"
          height="70"
          viewBox="0 0 55 150"
          fill="none"
        >
          <path
            d="M10.8789 -2.3387e-06L44.5507 -7.63132e-06C50.0735 -8.49941e-06 54.5507 4.47714 54.5507 9.99998L54.5507 140C54.5507 145.523 50.0735 150 44.5507 150L10.7884 150C3.70891 150 -1.11646 142.831 1.2951 136.175C17.3937 91.7418 18.5332 61.1219 1.37389 13.8981C-1.05212 7.2215 3.77519 -1.22212e-06 10.8789 -2.3387e-06Z"
            fill={`${colorTag}`}
          />
        </svg>
      )}
      <div
        className={`TitleSection__mainContainer ${
          positionTag === "right" ? `TitleSection__right` : ``
        } ${classname}`}
        onClick={accion}
      >
        <h1>{title}</h1>
      </div>
      {positionTag === "right" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="73"
          height="70"
          viewBox="0 0 73 188"
          fill="none"
        >
          <path
            d="M61.9071 188.008L10.8332 188.008C5.31036 188.008 0.833214 183.531 0.833215 178.008L0.833231 10.0083C0.833232 4.48545 5.31039 0.00829515 10.8332 0.00829558L62.0018 0.0082996C69.1371 0.00830016 73.9704 7.27843 71.4056 13.9368C48.8221 72.5652 47.1678 111.657 71.3214 174.022C73.9074 180.699 69.0673 188.008 61.9071 188.008Z"
            fill="url(#paint0_linear_1549_6968)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_1549_6968"
              x1="38.9166"
              y1="188.008"
              x2="38.9166"
              y2="0.00829779"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#009FAB" />
              <stop offset="1" stopColor="#004AAD" />
            </linearGradient>
          </defs>
        </svg>
      )}
    </div>
  );
};

export default TitleSection;

import axios from 'axios'

const CreateQuickCalculation = async (data) => {
  const response = await axios.post(
    'https://us-central1-sitlen.cloudfunctions.net/app/quickCalculation/',
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem('Token')}` },
    }
  )
  return response.data
}

const ConsultQuickCalculation = async (userInfo) => {
  const response = await axios.get(
    `https://us-central1-sitlen.cloudfunctions.net/app/quickCalculation/${userInfo}/all`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem('Token')}` },
    }
  )
  return response.data
}

const DeleteQuickCalculation = async (quickCalculation_id) => {
  const response = await axios.delete(
    `https://us-central1-sitlen.cloudfunctions.net/app/quickCalculation/delete/${quickCalculation_id}`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem('Token')}` },
    }
  )
  return response.data
}

const UpdateQuickCalculation = async (data, id) => {
  const response = await axios.put(
    `https://us-central1-sitlen.cloudfunctions.net/app/quickCalculation/update/${id}`,
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem('Token')}` },
    }
  )
  return response.data
}

export {
  CreateQuickCalculation,
  ConsultQuickCalculation,
  DeleteQuickCalculation,
  UpdateQuickCalculation,
}

const RevisionOfTextEntries = (name, namecategoy, setname) => {
  if (/^[a-zA-Z\s]*$/.test(name) && namecategoy === 'textOnly') {
    setname(name)
  }
  else if (/^[a-zA-Z0-9 ]*$/.test(name) && namecategoy === 'textWithnumbers') {
    setname(name)
  }
}

//! En caso de querer especificar que no se puedan ingresar espacios en blanco
// const handleKeyPress = (event, state ) => {
//   if (event.key === ' ' && state === '') {
//     event.preventDefault()
//   }
// }

export {RevisionOfTextEntries};

const NumberChecker = (input, setNumber) => {
  if (input.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) {
    setNumber(input)
  }
}

const NumberCheckerWhitSymbols = (input, setNumber, symbol) => {
  if (input.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) {
    setNumber(input + symbol)
  }
}

export { NumberChecker, NumberCheckerWhitSymbols }

import './AddQuickCalculation.scss'
//Se importa el contexto creado en el componente padre
import { globalDataAndFunctions } from "../.././molecules/interactiveContainerQuickCalculation/InteractiveContainerQuickCalculation"
import { useContext } from 'react'

const AddQuickCalculation = () => {
  //Se obtiene la función handleChangeCurrentmode del contexto global
  const { changeMode }= useContext(globalDataAndFunctions)

  return (
    <>
      <div className='firstQuickCalculation'>
        <h5 className='firstQuickCalculation__title'>
          ¡Añade Un Nuevo Cálculo!
        </h5>
      </div>
      <div
        className='ButtonInteractiveContainer newQuickCalculation'
        onClick={() => changeMode('quickCalculationForms')}
      >
        Nuevo Cálculo
      </div>
    </>
  )
}

export default AddQuickCalculation

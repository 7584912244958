const NoteNeededCalculation = (Arraynotas, noteAprobatory, setNoteNeeded ) => {
  const notaTotalActual = Arraynotas.map(nota => {
    return (nota.nota * nota.porcentaje) / 100
  })
  const notaTotal = notaTotalActual.reduce((a, b) => a + b, 0)
  if (notaTotal >= parseFloat(noteAprobatory)) {
    setNoteNeeded(0)
    return 0
  }
  const porcentajeTotal = Arraynotas.map(nota => nota.porcentaje).reduce(
    (a, b) => a + b,
    0
  )
  if (porcentajeTotal >= 100) {
    setNoteNeeded(0)
    return 0
  }
  const porcentajeFaltante = 100 - porcentajeTotal
  const notaNecesaria =
    ((parseFloat(noteAprobatory) - notaTotal) / porcentajeFaltante) * 100
  
  //*Dejar solo con dos decimales
  let numeroString = notaNecesaria.toString()
  const indiceDecimal = numeroString.indexOf('.')
  if (indiceDecimal !== -1) {
    numeroString = numeroString.substring(0, indiceDecimal + 2 + 1)
  }
  setNoteNeeded(parseFloat(numeroString))

  if (isNaN(parseFloat(numeroString))) {
    setNoteNeeded(0)
    return (0)
  } else{
    return parseFloat(numeroString)
  }
}

export default NoteNeededCalculation
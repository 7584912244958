import axios from "axios";

export const GetAllPomodoro = async (userId, type, limit) => {
  return axios.get(
    `https://us-central1-sitlen.cloudfunctions.net/app/pomodoro/${userId}/${type}/${limit}/web`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    }
  );
};

export const createNewPomodoro = async (data) => {
  return axios.post(
    `https://us-central1-sitlen.cloudfunctions.net/app/pomodoro`,
    {
      ...data,
    },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    }
  );
};

import { useState } from 'react'
import { Container, Image, Nav, Navbar } from 'react-bootstrap'
import './HeaderProfile.scss'
import BasicLayout from '../../templates/layout/BasicLayout'
import ModalUserInfo from '../modalUserInfo/ModalUserInfo'
import { useDispatch, useSelector } from 'react-redux'
import { signOut } from 'firebase/auth'
import { authentication } from '../../utilities/firebase/firebaseConfig'
import { deleteInfoUser } from '../../store/infoUser/actions'
import { useHistory } from 'react-router-dom'
import { actionLogout } from '../../store/login/actions'

const HeaderProfile = () => {
  const [showModal, setShowModal] = useState(false)
  const infoUser = useSelector(state => state.infoUser)
  const dispatch = useDispatch()
  const history = useHistory()

  const logout = () => {
    signOut(authentication)
      .then(response => {
        dispatch(deleteInfoUser())
        dispatch(actionLogout())
        localStorage.removeItem('Token')
        history.push('/')
      })
      .catch(error => {
        console.error(error)
      })
  }

  return (
    <>
      <ModalUserInfo showModal={showModal} setShow={setShowModal} />
      <Container
        fluid={true}
        className='container__Header px-3 d-flex justify-content-center'
        id='sectionHeader'
      >
        <BasicLayout>
          <Navbar collapseOnSelect expand='lg'>
            <Container fluid={true} className='px-0 px-md-3'>
              <Navbar.Brand href='/home'>
                <Image
                  src={require('../../app/img/home_Sitlen_log.png')}
                  alt='Sitlen'
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls='responsive-navbar-nav' />
              <Navbar.Collapse
                id='responsive-navbar-nav'
                className='justify-content-end'
              >
                <Nav>
                  <Image
                    className='d-none d-lg-block'
                    src={
                      infoUser.pictureURL ||
                      require('../../app/img/logo_pencil_primary.png')
                    }
                    height={40}
                    width={40}
                    onClick={() => {
                      setShowModal(true)
                    }}
                    alt='ImagenUser'
                  />
                  {infoUser.name && (
                    <Nav.Link
                      onClick={() => {
                        setShowModal(true)
                      }}
                    >
                      <div
                        className='d-flex'
                        style={{
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <p style={{ margin: 0 }}>{infoUser.name}</p>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='16'
                          height='16'
                          fill='currentColor'
                          className='bi bi-chevron-down d-block d-lg-none'
                          viewBox='0 0 16 16'
                        >
                          <path
                            fillRule='evenodd'
                            d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'
                          />
                        </svg>
                      </div>
                    </Nav.Link>
                  )}
                  <Nav.Link
                    className='d-none d-lg-block container__chevron_down'
                    onClick={() => {
                      setShowModal(true)
                    }}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='16'
                      height='16'
                      fill='currentColor'
                      className='bi bi-chevron-down'
                      viewBox='0 0 16 16'
                    >
                      <path
                        fillRule='evenodd'
                        d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'
                      />
                    </svg>
                  </Nav.Link>
                  {/*                    
                    <Button className="button__Login" onClick={() => logout()}>
                    CERRAR SESIÓN
                    </Button>
                  */}
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </BasicLayout>
      </Container>
    </>
  )
}

export default HeaderProfile

import axios from "axios";

const versionLogin = (setterLoginState) => {
  axios
    .get("https://us-central1-sitlen.cloudfunctions.net/app/login")
    .then((el) => {
      setterLoginState(el.data.flag);
    });
};

export { versionLogin };

import { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import "./ModalLoading.scss";

const ModalLoading = () => {
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "initial";
    };
  }, []);

  return (
    <div className="container__ModalLoading">
      <Image
        src={require("../../app/img/logo_pencil_primary.png")}
        alt="Sitlen"
      />
    </div>
  );
};

const ImageLoading= ({ src, fallback, alt }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const handleImageLoad = () => {
    setIsLoaded(true);
  };
  return (
    <>
      <img className={isLoaded ? "load" : "loading"}
        src={fallback}
        alt={alt}
      />
      <img
        src={src}
        alt={alt}
        onLoad={handleImageLoad}
      />
    </>
  );
}



export { ModalLoading, ImageLoading };

import { useState } from "react";
import "./AddPomodoro.scss";
import Icon from "../../atoms/Icon/Icon";
import SessionsPomodoro from "../../atoms/sessionsPomodoro/SessionsPomodoro";
import TimePomodoro from "../../atoms/timePomodoro/TimePomodoro";
import { createNewPomodoro } from "../../utilities/pomodoro";
import { useSelector } from "react-redux";

const AddPomodoro = () => {
  const [fieldAvailable, setFieldAvailable] = useState();
  const infoUser = useSelector((state) => state.infoUser);
  const [values, setValues] = useState();
  const handleChangeFieldAvailable = (value) => {
    setFieldAvailable(value);
  };

  console.log("infoUser", infoUser);
  const handleCreatePomodoro = () => {
    createNewPomodoro({
      userId: infoUser._id,
      materia: values?.title,
      tiempo: values?.time,
      sesiones: values?.sessions,
      diaHora: values?.dayAndHour,
      plataforma: "WEB",
    })
      .then(() => {
        console.log("PomodoroCreate");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangeOptions = (key, value) => {
    const auxValues = { ...values };
    auxValues[key] = value;
    setValues(auxValues);
  };

  console.log("values", values);

  return (
    <div className="externalContainer__formPomodoro">
      <h1>{"> Añade pomodoro"}</h1>
      <div className="container__formPomodoro">
        <input
          placeholder="Nombre de tu tema"
          value={values?.title}
          onChange={(e) => handleChangeOptions("title", e.target.value)}
        />
        <div className="container__filesFormPomodoro">
          <div className="container__firstFieldsFormPomodoro">
            <button
              onClick={() => {
                handleChangeFieldAvailable("time");
              }}
            >
              <p>Tiempo</p>
              {fieldAvailable === "time" ? (
                <Icon name="arrow_Right_Icon" size={20} />
              ) : (
                <Icon name="arrow_Down_Icon" size={20} />
              )}
            </button>
            <button
              onClick={() => {
                handleChangeFieldAvailable("sessions");
              }}
            >
              <p>Sesiones</p>
              {fieldAvailable === "sessions" ? (
                <Icon name="arrow_Right_Icon" size={20} />
              ) : (
                <Icon name="arrow_Down_Icon" size={20} />
              )}
            </button>
            <button
              onClick={() => {
                handleChangeFieldAvailable("dayHour");
              }}
            >
              <p>Día / Hora</p>
              {fieldAvailable === "dayHour" ? (
                <Icon name="arrow_Right_Icon" size={20} />
              ) : (
                <Icon name="arrow_Down_Icon" size={20} />
              )}
            </button>
          </div>
          <div className="container__optionsFormPomodoro">
            {fieldAvailable === "time" ? (
              <TimePomodoro values={values} setValues={handleChangeOptions} />
            ) : fieldAvailable === "sessions" ? (
              <SessionsPomodoro
                values={values}
                setValues={handleChangeOptions}
              />
            ) : (
              fieldAvailable === "dayHour" && (
                <input
                  value={values?.dayAndHour}
                  onChange={(e) =>
                    handleChangeOptions("dayAndHour", e.target.value)
                  }
                />
              )
            )}
          </div>
        </div>
        <button onClick={handleCreatePomodoro}>Crear</button>
      </div>
    </div>
  );
};

export default AddPomodoro;

import { Button, Col, Container, Image, Row } from "react-bootstrap";
import "./ApplicationInfo.scss";
import { FaGooglePlay } from "react-icons/fa";
import BasicLayout from "../../templates/layout/BasicLayout";

const ApplicationInfo = () => {
  return (
    <div className="div__ApplicationInfo">
      <Container
        className="container__ApplicationInfo justify-content-center d-flex"
        fluid={true}
      >
        <BasicLayout>
          <Row id="sectionApplicationInfo">
            <Col
              xs={12}
              md={8}
              xxl={10}
              className="d-xs-flex d-md-block justify-content-xs-center"
            >
              <Row>
                <h1>
                  ¡Descubre Sitlen! La mejor aplicación para practicar y mejorar
                  tus habilidades en todas las áreas de estudio.
                </h1>
              </Row>
              <Row>
                <p>Accede a todo nuestro contenido a través Google Play</p>
              </Row>
              <Row>
                <Col
                  xs={12}
                  md={4}
                  className="d-flex justify-content-xs-center"
                >
                  <Button
                    className="button__GooglePlay"
                    href="https://play.google.com/store/apps/details?id=com.sitlen.sitlen"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="d-flex align-items-center justify-content-center">
                      <FaGooglePlay className="icon__ApplicationInfo__GooglePlay" />
                      <p>Google Play</p>
                    </div>
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </BasicLayout>
      </Container>
      <div className="container__img__ApplicationInfo d-none d-xl-block">
        <Col md={4} xxl={2}>
          <Image
            src={require("../../app/img/imagenApp.png")}
            style={{ maxWidth: "35rem" }}
            alt="ImagenApp"
          />
        </Col>
      </div>
    </div>
  );
};

export default ApplicationInfo;

const addInfoUser = (payload) => {
  return {
    type: "ADDINFOUSER",
    payload: payload,
  };
};

const deleteInfoUser = (payload) => {
  return {
    type: "addInfoUser",
    payload: payload,
  };
};

export { addInfoUser, deleteInfoUser };

//Se importa el contexto creado en el componente padre
import { globalDataAndFunctions } from '../.././molecules/interactiveContainerQuickCalculation/InteractiveContainerQuickCalculation'
import { useContext } from 'react'
import './Quickcalculationresults.scss'
import {
  CreateQuickCalculation,
  UpdateQuickCalculation,
} from '../.././utilities/quickCalculation'
import { useSelector } from 'react-redux'

const Quickcalculationresults = () => {
  //Se obtiene la función handleChangeCurrentmode del contexto global
  const { data, changeMode, setUpdateConsult, dataToUpdate, setDataToUpdate } =
    useContext(globalDataAndFunctions)
  const infoUser = useSelector(state => state.infoUser)

  const creationQuickCalculationFunction = () => {
    const dataToEndpoint = {
      nombre: data.subjectName,
      descripcion: `Necesitas ${data.NoteNeeded} en tu ultima nota para aprobar la materia`,
      notaAprobatoria: parseFloat(data.noteAprobatory),
      notaMinima: parseFloat(data.noteMin),
      notaMaxima: parseFloat(data.noteMax),
      resultado: parseFloat(data.NoteNeeded),
      plataforma: 'web',
      userId: infoUser._id,
      Notas: data.Arraynotas,
    }
    CreateQuickCalculation(dataToEndpoint)
      .then(() => {
        setUpdateConsult(new Date().getTime())
        changeMode('history')
      })
      .catch(error => {
        console.error('Error en post', error)
      })
  }

  const UpdateQuickCalculationFunction = () => {
    const dataToEndpoint = {
      descripcion: `Necesitas ${data.NoteNeeded} en tu ultima nota para aprobar la materia`,
      notaMaxima: parseFloat(data.noteMax),
      resultado: parseFloat(data.NoteNeeded),
      notaMinima: parseFloat(data.noteMin),
      plataforma: 'web',
      notaAprobatoria: parseFloat(data.noteAprobatory),
      Notas: data.Arraynotas,
      nombre: data.subjectName,
      userId: infoUser._id,
    }
    UpdateQuickCalculation(dataToEndpoint, dataToUpdate.id)
      .then(() => {
        setUpdateConsult(new Date().getTime())
        setDataToUpdate()
        changeMode('history')
      })
      .catch(error => {
        console.error('Error en post', error)
      })
  }

  if (data === undefined) {
    changeMode('addQuickCalculation')
  } else {
    return (
      <>
        <div className='containerSubjecteviewResults'>
          <h1 className='subjecteview'>{data.subjectName}</h1>
        </div>

        <div className='finallViewNotes'>
          {data.Arraynotas.map((nota, index) => {
            return (
              <div key={index} className='summaryOfNotes'>
                <p className='nameNoteSumary'>{nota.nombre}</p>
                <p className='noteNoteSumary'>{nota.nota} </p>
              </div>
            )
          })}
        </div>

        <p>
          Necesitas {data.NoteNeeded} en tu ultima nota para aprobar la materia
        </p>

        {dataToUpdate !== undefined ? (
          <button
            onClick={() => {
              UpdateQuickCalculationFunction()
            }}
            className='submitButton'
          >
            Actualizar calculo rapido
          </button>
        ) : (
          <button
            onClick={() => {
              creationQuickCalculationFunction()
            }}
            className='submitButton'
          >
            Guardar calculo rapido
          </button>
        )}
      </>
    )
  }
}

export default Quickcalculationresults

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { BrowserRouter } from "react-router-dom/cjs/react-router-dom.min";
import ForgotPasswordPage from "../templates/forgotPassword/ForgotPasswordPage";
import Home from "../templates/home/Home";
import LoginAndRegister from "../templates/loginAndRegister/LoginAndRegister";
import Profile from "../templates/profile/Profile";
import PrivateRoute from "../atoms/privateRoute/PrivateRoute";
import { actionVerifyToken } from "../store/login/actions";
import Signature from "../templates/signature/Signature";
import Pomodoro from "../templates/pomodoro/Promodoro";
import QuickCalculation from "../templates/quickCalculation/QuickCalculation";
//import Chapter from "../templates/Chapter/Chapter";

const RoutesComponent = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("Token") !== null) {
      dispatch(actionVerifyToken());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/login" component={LoginAndRegister} />
        <PrivateRoute exact path="/home" component={Profile} />
        <PrivateRoute exact path="/materia/:id" component={Signature} />
        <PrivateRoute exact path="/pomodoro" component={Pomodoro} />
        <PrivateRoute exact path="/quickCalculation" component={QuickCalculation} />

        {/*<PrivateRoute
          exact
          path="/materia/:id/:idChapter"
          component={Chapter}
  />*/}
        <Route exact path="/forgotPassword" component={ForgotPasswordPage} />
      </Switch>
    </BrowserRouter>
  );
};

export default RoutesComponent;

import Icon from "../../atoms/Icon/Icon";
import CardTaskSchedule from "../../atoms/cardTaskSchedule/CardTaskSchedule";
import "./ScheduleTask.scss";

const ScheduleTask = ({ items, handleChangeAddPomodoro, state }) => {
  console.log("Items", items);
  return (
    <div className="mainContainer__ScheduleTask">
      <h1>
        {state === "completed"
          ? "> Tareas Completadas"
          : "> Tareas programadas"}
      </h1>
      {items?.length > 0 ? (
        <div className="cards_Container">
          {items.map((el) => {
            return <CardTaskSchedule info={el} state={state} />;
          })}
        </div>
      ) : (
        <h4>
          No tienes tareas{" "}
          {state === "completed" ? "completadas" : "programadas"} ¡Toca el botón
          para añadir una nueva tarea!
        </h4>
      )}
      <div className="container__addPomodoroIcon">
        <button onClick={handleChangeAddPomodoro}>
          <Icon name="add_Icon" size={90} />
        </button>
      </div>
    </div>
  );
};

export default ScheduleTask;

import './InteractiveContainerQuickCalculation.scss'
import AddQuickCalculation from '../../atoms/addQuickCalculation/AddQuickCalculation'
import QuickCalculationForms from '../quickCalculationForms/QuickCalculationForms'
import Quickcalculationresults from '../quickcalculationresults/Quickcalculationresults'
import QuickCalculationHistory from '../quickCalculationHistory/QuickCalculationHistory'
import { useLocation, useHistory } from 'react-router-dom'

import React, { useState } from 'react'

export const globalDataAndFunctions = React.createContext()

const InteractiveContainerQuickCalculation = () => {
  const query = new URLSearchParams(useLocation().search)
  const history = useHistory()
  const site = query.get('site')
  const [data, setData] = useState()
  const [dataToUpdate, setDataToUpdate] = useState()
  const [updateConsult, setUpdateConsult] = useState(false)

  const handleChangeCurrentmode = value => {
    history.push(`?site=${value}`)
  }

  const dataFunctions = {
    changeMode: handleChangeCurrentmode,
    //Informacion que se manda a la base de datos
    data: data,
    setData: setData,
    //Infroamacion que se trae del historial (base de datos) para editar
    dataToUpdate: dataToUpdate,
    setDataToUpdate: setDataToUpdate,
    //Actualizar la consuta en el historial desde la base de datos
    updateConsult: updateConsult,
    setUpdateConsult: setUpdateConsult,
  }

  return (
    //Estado global, de manera que cualquier componente dentro de este contenedor pueda acceder a la función handleChangeCurrentmode
    //TODO: Implementar la funcionalidad del breadcrumb
    <globalDataAndFunctions.Provider value={dataFunctions}>
      <h3 className='BreadCrumbs__text'>
        {`${site ? '  > CALCULO RÁPIDO ' : ''} ${
          site !== 'addQuickCalculation' && site !== 'history' ? '  > NUEVO CÁLCULO ' : ''
        }`}
      </h3>
      <div className='InteractiveContainerQuickCalculation'>
        {site === null && <QuickCalculationHistory />}
        {site === 'addQuickCalculation' && <AddQuickCalculation />}
        {site === 'quickCalculationForms' && <QuickCalculationForms />}
        {site === 'result' && <Quickcalculationresults />}
        {site === 'history' && <QuickCalculationHistory />}
      </div>
    </globalDataAndFunctions.Provider>
  )
}

export default InteractiveContainerQuickCalculation

/* Query es una instancia de la clase URLSearchParams, que proporciona métodos para trabajar con la cadena de consulta de la URL. En este caso, query se utiliza para acceder a los parámetros de la cadena de consulta de la URL actual.
El método useLocation().search devuelve la cadena de consulta de la URL, que incluye el signo de interrogación inicial. Por ejemplo, si la URL es http://localhost:3000/?site=quickCalculationForms, entonces useLocation().search devolverá ?site=quickCalculationForms.
Luego, query.get('site') se utiliza para obtener el valor del parámetro site de la cadena de consulta. En el ejemplo anterior, query.get('site') devolvería 'quickCalculationForms'.
Por otro lado, site es una variable que almacena el valor del parámetro site de la cadena de consulta. Este valor se utiliza para determinar qué componente se debe renderizar. Si site es null, se renderiza el componente AddQuickCalculation. Si site es 'quickCalculationForms', se renderiza el componente QuickCalculationForms.
*/

import { useState } from 'react'
import { Button, Container, Image, Nav, Navbar } from 'react-bootstrap'
import { Link as LinkDom } from 'react-router-dom'
import { Link } from 'react-scroll'
import './Header.scss'
import ModalComingSoon from '../../atoms/modalComingSoon/ModalComingSoon'
import BasicLayout from '../../templates/layout/BasicLayout'
import { versionLogin } from '../../utilities/versionsServices'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { signOut } from 'firebase/auth'
import { authentication } from '../../utilities/firebase/firebaseConfig'
import { deleteInfoUser } from '../../store/infoUser/actions'
import { actionLogout } from '../../store/login/actions'

const Header = () => {
  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState(false)
  const [loginActive, setLoginActive] = useState(true)
  const isLogin = useSelector(state => state.login)
  const infoUser = useSelector(state => state.infoUser)
  useEffect(() => {
    versionLogin(setLoginActive)
  }, [])
  const history = useHistory()
  const logout = () => {
    signOut(authentication)
      .then(response => {
        dispatch(deleteInfoUser())
        dispatch(actionLogout())
        localStorage.removeItem('Token')
        history.push('/')
      })
      .catch(error => {
        console.error(error)
      })
  }

  return (
    <>
      <ModalComingSoon showModal={showModal} setShow={setShowModal} />
      <Container
        fluid={true}
        className='container__Header px-3 d-flex justify-content-center'
        id='sectionHeader'
      >
        <BasicLayout>
          <Navbar collapseOnSelect expand='lg'>
            <Container fluid={true} className='px-0 px-md-3'>
              <Navbar.Brand href='#home'>
                <Image
                  src={require('../../app/img/home_Sitlen_log.png')}
                  alt='imagenSitel'
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls='responsive-navbar-nav' />
              <Navbar.Collapse
                id='responsive-navbar-nav'
                className='justify-content-end'
              >
                <Nav>
                  <Nav.Link
                    onClick={() => {
                      history.push('/home')
                    }}
                  >
                    HOME
                  </Nav.Link>
                  <Nav.Link as={Link} to='sectionBasicInformation'>
                    NOSOTROS
                  </Nav.Link>
                  <Nav.Link as={Link} to='sectionBenefits'>
                    QUÉ OFRECEMOS
                  </Nav.Link>
                  <Nav.Link as={Link} to='sectionBenefits'>
                    NUESTRA APP
                  </Nav.Link>
                  {isLogin.isAuthenticated ? (
                    <>
                      <Button
                        className='button__Login'
                        onClick={() => logout()}
                      >
                        CERRAR SESIÓN
                      </Button>
                    </>
                  ) : loginActive ? (
                    <LinkDom className='button__Login' to={'/login'}>
                      <h6>INICIAR SESIÓN</h6>
                    </LinkDom>
                  ) : (
                    <Button
                      className='button__Login'
                      onClick={() => {
                        setShowModal(true)
                      }}
                    >
                      INICIAR SESIÓN
                    </Button>
                  )}
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </BasicLayout>
      </Container>
    </>
  )
}

export default Header

const initialState = {
  token: "",
  _id: "",
  name: "",
  email: "",
  pictureURL: "",
};

export const infoUser = (state = initialState, action) => {
  switch (action.type) {
    case "ADDINFOUSER":
      return {
        ...state,
        token: action.payload.token,
        _id: action.payload._id,
        name: action.payload.name,
        email: action.payload.email,
        pictureURL: action.payload.pictureURL,
      };
    case "DELETEINFOUSER":
      return {
        token: "",
        _id: "",
        name: "",
        email: "",
        pictureURL: "",
      };
    default:
      return state;
  }
};

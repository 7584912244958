import { Col, Container, Image, Row } from "react-bootstrap";
import ForgotPassword from "../../molecules/forgotPassword/ForgotPassword";
import "./ForgotPasswordPage.scss";

const ForgotPasswordPage = () => {
  return (
    <Container className="container__ForgotPasswordPage" fluid>
      <Row className="container__ChildrenForgotPasswordPage col justify-content-space-around">
        <Col
          className="d-none d-lg-flex justify-content-center align-content-center"
          lg={6}
          xl={7}
        >
          <Image
            src={require("../../app/img/imageLogin.png")}
            alt="ImagenComputadorLog"
          />
        </Col>
        <Col className="d-none d-lg-flex d-xl-none" lg={1}></Col>
        <Col
          xs={12}
          lg={4}
          xl={5}
          className="external_container_ForgotPassword_container d-flex px-0 px-xl-3"
        >
          <ForgotPassword />
        </Col>
      </Row>
    </Container>
  );
};

export default ForgotPasswordPage;

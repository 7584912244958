import Icon from '../../atoms/Icon/Icon'
import './QuickCalculationPage.scss'
import BasicLayout from '../../templates/layout/BasicLayout'
import InteractiveContainerQuickCalculation from '../../molecules/interactiveContainerQuickCalculation/InteractiveContainerQuickCalculation'

const QuickCalculationPage = () => {
  return (
    <div className='externalContainer__QuickCalculationPage'>
      <BasicLayout>
        <div className='container__calculoRapidoIcon'>
          <Icon name='calculo_rapido_Icon' size={400} />
        </div>
        <div className='location__InteractiveQuickCalculation'>
          <InteractiveContainerQuickCalculation/>
        </div>
      </BasicLayout>
    </div>
  )
}

export default QuickCalculationPage

import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ModalLoading } from "../../atoms/modalLoading/ModalLoading";
import SubjectLetterProfile from "../../atoms/subjectLetterProfile/SubjectLetterProfile";
import BasicLayout from "../../templates/layout/BasicLayout";
import { GetAllTopics } from "../../utilities/tipics";
import TitleSection from "../../atoms/TitleSection/TitleSection";
import "./SubjectProfile.scss";

const SubjectProfile = () => {
  const [subjects, setSubjects] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    GetAllTopics(setLoading, setSubjects)
      .then((data) => {
        setLoading(false);
        setSubjects(data.data.Temas);
      })
      .catch((error) => {
        console.error("Error en get", error);
        setLoading(false);
      });
  }, []);

  return (
    <>
      {loading ? (
        <ModalLoading />
      ) : (
        <Container fluid className="container__SubjectProfile">
          <BasicLayout>
            <div className="container__TitleSection">
              <TitleSection
                classname={"TitleMaterias"}
                colorTag={"#1d6eda"}
                positionTag={"left"}
                title={"Materias"}
              />
            </div>
            <Row className="d-flex justify-content-center">
              {subjects.map((el, index) => {
                return (
                  <Col
                    xs={12}
                    md={6}
                    lg={4}
                    xl={3}
                    className="d-flex justify-content-center"
                    key={`column-subject-letter-${index}`}
                  >
                    <SubjectLetterProfile item={el} />
                  </Col>
                );
              })}
            </Row>
          </BasicLayout>
        </Container>
      )}
    </>
  );
};

export default SubjectProfile;

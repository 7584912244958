import { Col, Container, Image, Row } from "react-bootstrap";
import CardInfo from "../../atoms/cardInfo/CardInfo";
import "./BasicInformation.scss";

const BasicInformation = () => {
  return (
    <Container className="container__BasicInformation" fluid={true}>
      <Row>
        <Col>
          <Row>
            <h1>
              Bienvenido a este espacio diseñado para potencializar tus
              habilidades de estudio.
            </h1>
          </Row>
          <Row>
            <p>
              En Sitlen entendemos las dificultades que atraviesan los
              estudiantes cuando se trata de aprender, por eso estamos
              comprometidos en potencializar tus habilidades de estudio. Somos
              una comunidad que ama los retos y el trabajo el equipo.
            </p>
          </Row>
        </Col>
        <Col className="d-none d-lg-block">
          <Image
            src={require("../../app/img/fotoPortada.png")}
            alt="imagenPortadaMujerSentada"
          />
        </Col>
      </Row>
      <Row className="mt-8 align-items-center" id="sectionBasicInformation">
        <Col xs={1} className="d-none d-lg-block">
          <Image
            src={require("../../app/img/imagenLapiz.png")}
            alt="ImagenLapíz"
          />
        </Col>
        <Col>
          <Row>
            <Col xs={12} md={4} className="mb-3 mb-md-0">
              <CardInfo
                title="¿Qué es Sitlen?"
                info="Nuestro objetivo es difundir el conocimiento empleando distintos métodos de aprendizaje. Sitlen cuenta con varias herramientas que se ajustan al perfil de cada estudiante."
              />
            </Col>
            <Col xs={12} md={4} className="mb-3 mb-md-0">
              <CardInfo
                title="¿Qué nos impulsó a crear Sitlen?"
                info="Sitlen nace como una solución a los problemas que causan los métodos tradicionales de aprendizaje. Es una herramienta que le permite a los estudiantes asumir el conocimiento desde otras perspectivas, ayudandoles a superar sus falencias, o a construir nuevos saberes."
              />
            </Col>
            <Col xs={12} md={4} className="mb-3 mb-md-0">
              <CardInfo
                title="¿Cuál es nuestro objetivo?"
                info="Nuestra finalidad es cambiar la forma en que los estudiantes latinoamericanos de todas las edades asumen el aprendizaje, fortaleciendo sus capacidades y preparandolos para la vida académica."
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default BasicInformation;

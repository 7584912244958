import { Col, Container, Image, Row } from "react-bootstrap";
import { BsFacebook, BsInstagram, BsLinkedin, BsYoutube } from "react-icons/bs";
import "./Footer.scss";

const Footer = () => {
  return (
    <Container className="container__footer" fluid={true}>
      <Container>
        <Row className="aling-items-start align-items-md-center">
          <Col className="d-flex justify-content-center" xs={12} md={3}>
            <Image
              src={require("../../app/img/imagenFooter.png")}
              alt="Sitlen"
            />
          </Col>
          <Col>
            <Row>
              <Col>
                <Row>
                  <h5>Nosotros</h5>
                </Row>
                <Row>
                  <p>¿Quiénes somos?</p>
                </Row>
                <Row>
                  <p>NewSletter</p>
                </Row>
                <Row>
                  <p>Contacto</p>
                </Row>
              </Col>
              <Col>
                <Row>
                  <h5>Contacto</h5>
                </Row>
                <Row>
                  <p>¿Quiénes somos?</p>
                </Row>
                <Row>
                  <p>NewSletter</p>
                </Row>
                <Row>
                  <p>Contacto</p>
                </Row>
              </Col>
              <Col>
                <Row>
                  <h5>¿Qué hacemos?</h5>
                </Row>
                <Row>
                  <p>Preguntas frecuentes</p>
                </Row>
                <Row>
                  <p>Politican de Privacidad</p>
                </Row>
                <Row>
                  <p>Terminos y condiciones de uso</p>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col className="d-flex justify-content-center justify-content-md-start">
                    <h5>Síguenos en</h5>
                  </Col>
                </Row>
                <Row>
                  <Col xs={3} className="d-flex justify-content-center p-0">
                    <a
                      href="https://www.instagram.com/sitlen_education/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <BsInstagram className="icon__Footer" />
                    </a>
                  </Col>
                  <Col xs={3} className="d-flex justify-content-center">
                    <a
                      href="https://www.facebook.com/SitlenEducation"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <BsFacebook className="icon__Footer" />
                    </a>
                  </Col>
                  <Col xs={3} className="d-flex justify-content-center">
                    <a
                      href="https://www.youtube.com/channel/UC-dcyfqxuqj6B7whZCgTtVA"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <BsYoutube className="icon__Footer" />
                    </a>
                  </Col>
                  <Col xs={3} className="d-flex justify-content-center">
                    <a
                      href="https://www.linkedin.com/company/sitlen/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <BsLinkedin className="icon__Footer" />
                    </a>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Footer;

import { useEffect, useState, useContext} from 'react'
import { useSelector } from 'react-redux'
import './QuickCalculationHistory.scss'
import Icon from '../../atoms/Icon/Icon'
import ModalInteractiveMessage from '../../atoms/modalInteractiveMessage/ModalInteractiveMessage'
import ButtonList from '../../atoms/buttonList/ButtonList'
import { ConsultQuickCalculation, DeleteQuickCalculation } from '../../utilities/quickCalculation'
import { globalDataAndFunctions } from '../.././molecules/interactiveContainerQuickCalculation/InteractiveContainerQuickCalculation'
import { ModalLoading } from '../../atoms/modalLoading/ModalLoading'

const QuickCalculationHistory = () => {
  let quickCalculationsNames = []
  //!Context
  const { changeMode, data, setData, setDataToUpdate, updateConsult, setUpdateConsult} = useContext(globalDataAndFunctions)
  const infoUser = useSelector(state => state.infoUser)
  //!State
  const [infoNotas, setInfoNotas] = useState()
  const [descriptionQuickCalculations, setDescriptionQuickCalculations] =
    useState()
  const [quickCalculationId, setQuickCalculationId] = useState()
  const [indexToEdit, setindexToEdit] = useState(0)
  //!ModalLoading
  const [loading, setLoading] = useState(true)
  //!InteractiveMessage Modal
  const [messageShowModal] = useState('¿Deseas eliminar este calculo?')
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    if (infoUser._id){
      ConsultQuickCalculation(infoUser._id)
      .then(data => {
        setData(data)
        //Si no hay documentos para este usuario, se cambia el modo a addQuickCalculation
        if (data.message === 'No hay documentos para este usuario.') {
          changeMode('addQuickCalculation')
          setLoading(false)
        } else if (data) {
          //Si hay documentos, se mantiene en el modo historial
          changeMode('history')
          setLoading(false)
        }
      })
      .catch(error => {
        console.error('Error en get', error)
      })
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoUser._id, updateConsult, setData, setUpdateConsult])

  const creationNewQuickNote = () => {
    changeMode('quickCalculationForms')
  }

  const editQuickCalculation = () => {
    const dataToEdit = data[indexToEdit]
    setDataToUpdate(dataToEdit)
    changeMode('quickCalculationForms')
  }

  const deleteQuickCalculation = id_QuickCalculation => {
    DeleteQuickCalculation(id_QuickCalculation)
      .then(() => {
        //Se actualiza el estado para que se deje de mostrar la información eliminada
        setInfoNotas(null)
        setUpdateConsult(new Date().getTime())
      })
      .catch(error => {
        console.error('Error en delete', error)
      })
  }

  if (data && data.length > 0) {
    quickCalculationsNames = data.map((note, index) => ({
      className: 'ButtonInteractiveContainer FormButtonQuickCalculation',
      onClick: () => {
        calculationInfo(note.Notas, note.descripcion, note.id, index)
      },
      children: note.nombre,
    }))
  }

  const calculationInfo = (notas, description, id, index) => {
    setInfoNotas(notas)
    setDescriptionQuickCalculations(description)
    setQuickCalculationId(id)
    setindexToEdit(index)
  }

  return loading ?(
    <ModalLoading />
  ) : (
    <>
      <ModalInteractiveMessage
        showModal={showModal}
        setShow={setShowModal}
        message={messageShowModal}
        functionToExecute={() => deleteQuickCalculation(quickCalculationId)}
      />
      {/* //Estilos traidos desde QuickCalculationForms */}
      <div className='interactionContainer'>
        <div className='interactionContainer__functionSelector'>
          <ButtonList buttons={quickCalculationsNames} />
        </div>
        <>
          <div className='informationHistory'>
            {infoNotas && (
              <>
                <div className='informationHistory__notes finallViewNotes'>
                  {infoNotas.map((infnote, index) => {
                    return (
                      <div key={index} className='summaryOfNotes'>
                        <p className='nameNoteSumary'>{infnote.nombre}</p>
                        <p className='noteNoteSumary'>{infnote.nota} </p>
                      </div>
                    )
                  })}
                </div>
                <p className='historyDescription'>
                  {descriptionQuickCalculations}
                </p>
                <div className='functionalities'>
                  <Icon
                    name='black_pencil'
                    size={30}
                    className='functionality'
                    onClick={() => editQuickCalculation()}
                  />
                  <Icon
                    name='black_trash'
                    size={30}
                    className='functionality'
                    onClick={() => {
                      setShowModal(true)
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </>

        <button
          onClick={creationNewQuickNote}
          className='SkipButton newQuickCalculation'
        >
          Nuevo calculo
        </button>
      </div>
    </>
  )
}

export default QuickCalculationHistory

import { Container } from "react-bootstrap";
import "./BasicLayout.scss";

const BasicLayout = ({ children }) => {
  return (
    <div className="externalBasicLayout">
      <div className="basicLayout">
        <Container fluid={true}>{children}</Container>
      </div>
    </div>
  );
};

export default BasicLayout;

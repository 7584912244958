import "./SubjectLetterInfo.css";
import { Container, Col, Row, Image } from "react-bootstrap";

const SubjectLetterInfo = ({ item }) => {
  return (
    <Container className="container__SubjectLetterInfo" fluid={true}>
      <div className="div__SubjectLetterInfo">
        <Row style={{ alignItems: "center" }}>
          <Col xs={5}>
            <Image
              src={require(`../../app/img/${item.imageLogo}.png`)}
              width={"700px"}
              alt={item.title}
            />
          </Col>
          <Col xs={4}>
            <Row>
              <h1>{item.title}</h1>
            </Row>
            <Row>
              <p>{item.info}</p>
            </Row>
          </Col>
          <Col xs={3}>
            <Image
              src={require(`../../app/img/${item.imageApp}.png`)}
              alt={item.title}
              className="container__SubjectLetterInfo_image"
            />
          </Col>
        </Row>
        <div className="div__background__SubjectLetterInfo"></div>
      </div>
    </Container>
  );
};

export default SubjectLetterInfo;

// Componente de Botón
const Button = ({ className, onClick, children }) => {
  return (
    <div className={className} onClick={onClick}>
      {children}
    </div>
  )
}

// Componente de Lista de Botones
const ButtonList = ({ buttons }) => {
  return (
    <>
      {buttons.map((button, index) => (
        <Button key={index} {...button} />
        
      //! ES EQUIVALENTE A HACER 
      //  <Button 
      //   key={index} 
      //   className={button.className} 
      //   onClick={button.onClick}
      //   >
      //   {button.children}
      //   </Button>
      ))}
    </>
  )
}

export default ButtonList

import { Modal } from "react-bootstrap";
import "./ModalAlertMessage.css";
const ModalAlertMessage = ({ showModal, setShow, message }) => {
  return (
    <Modal show={showModal} onHide={() => setShow(false)}>
      <div className="modal__ModalAlertMessage">
        <p>{message}</p>
      </div>
    </Modal>
  );
};

export default ModalAlertMessage;

import "./CardTaskPomodoro.scss";

const CardTaskPomodoro = ({ title, items, titleEmpty, onClick }) => {
  return (
    <button className="CardTaskPomodoro__mainContainer" onClick={onClick}>
      <div className="container__Title">
        <h6>{title}</h6>
        <button>
          <h6>{">"}</h6>
        </button>
      </div>
      {items.length > 0 ? (
        items.map((el) => {
          return el;
        })
      ) : (
        <h4>{titleEmpty}</h4>
      )}
    </button>
  );
};

export default CardTaskPomodoro;

import { Col, Container, Row } from "react-bootstrap";
import "./ChannelBar.scss";
import { BsYoutube, BsInstagram, BsFacebook, BsLinkedin } from "react-icons/bs";

const ChannelBar = () => {
  return (
    <Container className="container__ChannelBar" fluid={true}>
      <Container>
        <Row>
          <p>
            ¡Encúentranos en nuestras redes sociales para conocer más de
            nosotros!
          </p>
        </Row>
        <Row className="justify-content-center">
          <Col xs={3} md={1} className="d-flex justify-content-center">
            <a
              href="https://www.instagram.com/sitlen_education/"
              target="_blank"
              rel="noreferrer"
            >
              <BsInstagram className="icon__ChannelBar" />
            </a>
          </Col>
          <Col xs={3} md={1} className="d-flex justify-content-center">
            <a
              href="https://www.facebook.com/SitlenEducation"
              target="_blank"
              rel="noreferrer"
            >
              <BsFacebook className="icon__ChannelBar" />
            </a>
          </Col>
          <Col xs={3} md={1} className="d-flex justify-content-center">
            <a
              href="https://www.youtube.com/channel/UC-dcyfqxuqj6B7whZCgTtVA"
              target="_blank"
              rel="noreferrer"
            >
              <BsYoutube className="icon__ChannelBar" />
            </a>
          </Col>
          <Col xs={3} md={1} className="d-flex justify-content-center">
            <a
              href="https://www.linkedin.com/company/sitlen/"
              target="_blank"
              rel="noreferrer"
            >
              <BsLinkedin className="icon__ChannelBar" />
            </a>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default ChannelBar;

import { Carousel, Col, Container, Image, Row } from "react-bootstrap";
import BenefitsCard from "../../atoms/benefitsCard/BenefitsCard";
import "./Benefits.scss";

const Benefits = () => {
  return (
    <Container className="container__Benefits" id="sectionBenefits">
      <Row>
        <Col className="d-none d-lg-block">
          <Image
            src={require("../../app/img/imagenBenefits.png")}
            alt="ImagenJovenesSaltando"
          />
        </Col>
        <Col>
          <Row>
            <h5>Conoce los beneficios de estudiar con Sitlen</h5>
          </Row>
          <Row>
            <p>
              Sitlen es una plataforma diseñada para mejorar la manera en que
              aprendes.
            </p>
          </Row>
          <Row>
            <Carousel variant="dark">
              <Carousel.Item>
                <Row className="justify-content-sm-center">
                  <Col xs={12} md={10}>
                    <BenefitsCard
                      title="Aprendizaje"
                      info="¡Sitlen esta en constante renovación! Por eso usamos diferentes métodos para adecuarnos a tu forma de aprender."
                    />
                  </Col>
                </Row>
              </Carousel.Item>
              <Carousel.Item>
                <Row className="justify-content-sm-center">
                  <Col xs={12} md={10}>
                    <BenefitsCard
                      title="Funcionalidades"
                      info="Sitlen es una herramienta de estudio diseñada exclusivamente para ti."
                    />
                  </Col>
                </Row>
              </Carousel.Item>
              <Carousel.Item>
                <Row className="justify-content-sm-center">
                  <Col xs={12} md={10}>
                    <BenefitsCard
                      title="Alcance"
                      info="Al usar Sitlen estas contribuyendo a que más estudiantes puedan tener todas estas herramientas al alcance de sus manos."
                    />
                  </Col>
                </Row>
              </Carousel.Item>
            </Carousel>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Benefits;

import { takeEvery } from "redux-saga/effects";

import { loginSaga, verifyTokenSaga } from "./login/sagas";

function* rootSaga() {
  yield takeEvery("LOGIN", loginSaga);
  yield takeEvery("VERIFY_TOKEN", verifyTokenSaga);
}

export default rootSaga;

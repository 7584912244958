import { Image } from "react-bootstrap";
import TitleSection from "../../atoms/TitleSection/TitleSection";
import BasicLayout from "../../templates/layout/BasicLayout";
import "./ClassroomSection.scss";
import ModalComingSoon from "../../atoms/modalComingSoon/ModalComingSoon";
import { useState } from "react";

const ClassroomSection = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <ModalComingSoon showModal={showModal} setShow={setShowModal} />
      <div className="container__ClassroomSection">
        <BasicLayout>
          <TitleSection
            classname={"TitleClassroomSection"}
            colorTag={"#009FAB"}
            positionTag={"left"}
            title={"Classroom"}
          />
          <div className="d-flex container__DescriptionAndImage">
            <div>
              <p>
                Nos apasionamos por la educación, por las personas y tenemos un
                compromiso con estas, para que disfruten de una educación de
                calidad pero disfrutando el proceso para adquirir conocimientos
                nuevos.
              </p>
              <button
                onClick={() => {
                  setShowModal(true);
                }}
              >
                <p>IR A SALAS</p>
              </button>
            </div>
            <div>
              <Image
                src={require("../../app/img/imageClassroom.png")}
                alt="Sitlen"
              />
            </div>
          </div>
        </BasicLayout>
      </div>
    </>
  );
};

export default ClassroomSection;

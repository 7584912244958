import ChannelBar from "../../molecules/channelBar/ChannelBar";
import ClassroomSection from "../../molecules/classroomSection/ClassroomSection";
import Footer from "../../molecules/footer/Footer";
import HeaderProfile from "../../molecules/headerProfile/HeaderProfile";
import PomodoroSection from "../../molecules/pomodoroSection/PomodoroSection";
import QuickCalculation from "../../molecules/quickCalculationSection/QuickCalculation";
import SubjectProfile from "../../molecules/subjectProfile/SubjectProfile";
import VideoSection from "../../organisms/videosSection/VideoSection";
import "./Profile.scss";

const Profile = () => {
  return (
    <>
      <HeaderProfile />
      <SubjectProfile />
      <PomodoroSection />
      <div className="container__QuickClassExtra">
        <QuickCalculation />
        <ClassroomSection />
        <VideoSection />
      </div>
      <ChannelBar />
      <Footer />
    </>
  );
};

export default Profile;

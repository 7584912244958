import { useHistory, useLocation } from "react-router-dom";
import "./ContainerListChapter.scss";
import { Image } from "react-bootstrap";

const ContainerListChapter = ({ items, hasTitles, titles }) => {
  const history = useHistory();
  const location = useLocation();

  const handleGoChapter = (id, nameParam) => {
    if (nameParam === "capitulo") {
      history.push(`?${nameParam}=${id}`);
    } else if (nameParam === "titulo") {
      history.push(
        `?${location.search.split("?")[1].split("&")[0]}&${nameParam}=${id}`
      );
    }
  };

  return (
    <div className="containerContentsComponent">
      <div className="container__titleSection">
        <h2>Capitulos</h2>
      </div>
      <div className="container__chapters">
        {items?.map((el, index) => {
          return (
            <div
              className="subtitleChapter"
              key={`subtitle-chapter-${index}`}
              onClick={() => {
                handleGoChapter(el.Nombre, "capitulo");
              }}
            >
              <h2>{el.Nombre}</h2>
            </div>
          );
        })}
      </div>
      {hasTitles && (
        <div className="container__titleSection">
          <h2>Titulos</h2>
        </div>
      )}
      <div className="container__titles">
        {hasTitles ? (
          titles?.map((el, index) => {
            return (
              <div
                className="subtitleChapter"
                key={`subtitle-chapter-${index}`}
                onClick={() => {
                  handleGoChapter(el.Nombre, "titulo");
                }}
              >
                <h2>{el.Nombre}</h2>
              </div>
            );
          })
        ) : (
          <div className="container__titlesEmpty">
            <Image
              src={require("../../app/img/imageNotSelectionSubject.png")}
              width={400}
              height={300}
            />
            <h2>¡Elíge tu materia para ver los temas que puedes estudiar!</h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContainerListChapter;

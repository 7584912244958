import React, { useState } from "react";
import Icon from "../../atoms/Icon/Icon";
import TitleSection from "../../atoms/TitleSection/TitleSection";
import BasicLayout from "../../templates/layout/BasicLayout";
import "./PomodoroSection.scss";
import ModalComingSoon from "../../atoms/modalComingSoon/ModalComingSoon";
import { useHistory } from "react-router-dom";

const PomodoroSection = () => {
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();

  const handleGoPomodoroSection = () => {
    history.push("/pomodoro");
  };

  return (
    <>
      <ModalComingSoon showModal={showModal} setShow={setShowModal} />
      <div className="container__PomodoroSection">
        <BasicLayout>
          <div className="d-flex align-items-center container__titleIcon">
            <div
              className="contianer__pomodoro_Icon"
              //onClick={handleGoPomodoroSection}
              onClick={() => setShowModal(true)}

            >
              <Icon name="pomodoro_Icon" size={250} />
            </div>
            <div>
              <TitleSection
                classname={"TitlePomodoro"}
                colorTag={"#264F80"}
                positionTag={"left"}
                title={"Pomodoro"}
                // accion={handleGoPomodoroSection}
                accion={() => setShowModal(true)}
              />
            </div>
          </div>
        </BasicLayout>
      </div>
    </>
  );
};

export default PomodoroSection;

import { signOut } from "firebase/auth";
import { useState } from "react";
import { Button, Col, Container, Image, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ModalComingSoon from "../../atoms/modalComingSoon/ModalComingSoon";
import { deleteInfoUser } from "../../store/infoUser/actions";
import { actionLogout } from "../../store/login/actions";
import { authentication } from "../../utilities/firebase/firebaseConfig";
import "./ModalUserInfo.scss";

const ModalUserInfo = ({ showModal, setShow }) => {
  const [showModalComingSoon, setShowModalComingSoon] = useState(false);
  const infoUser = useSelector((state) => state.infoUser);
  const dispatch = useDispatch();
  const history = useHistory();

  const logout = () => {
    signOut(authentication)
      .then((response) => {
        dispatch(deleteInfoUser());
        dispatch(actionLogout());
        localStorage.removeItem("Token");
        history.push("/");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      {/*<ModalLoading />*/}
      <ModalComingSoon
        showModal={showModalComingSoon}
        setShow={setShowModalComingSoon}
      />
      <Modal show={showModal} onHide={() => setShow(false)} className="modal">
        <Container className="container__ModalUserInfo">
          <Col xs={9} className="offset-1">
            <Row className="align-items-center mb-3">
              <Col className="d-flex justify-content-center mb-3">
                <Image
                  src={
                    infoUser.pictureURL ||
                    require("../../app/img/logo_pencil_primary.png")
                  }
                  width={"150px"}
                  alt="ImagenUser"
                />
              </Col>
              <Col className="d-flex justify-content-center">
                <p className="p__name">{infoUser.name}</p>
              </Col>
            </Row>
            <Row>
              <hr className="hr" />
              <Col className="d-flex justify-content-center">
                <p className="p__email">{infoUser.email}</p>
              </Col>
              <hr className="hr" />
            </Row>
            <Row className="d-flex justify-content-center">
              <Col xs={10}>
                <Button
                  className="button__EditProfile"
                  onClick={(e) => {
                    setShowModalComingSoon(true);
                  }}
                >
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-pencil-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                    </svg>
                    Editar perfil
                  </div>
                </Button>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col xs={10}>
                <Button className="button__logout" onClick={() => logout()}>
                  Cerrar sesión
                </Button>
              </Col>
            </Row>
          </Col>
        </Container>
      </Modal>
    </>
  );
};

export default ModalUserInfo;

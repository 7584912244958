import React from "react";
import "./Icon.scss";
import Add_Icon from "../../Icons/Add_Icon.svg";
import Arrow_Down_Icon from "../../Icons/Arrow_Down_Icon.svg";
import Arrow_Right_Icon from "../../Icons/Arrow_Right_Icon.svg";
import Calculo_rapido_Icon from "../../Icons/Calculo_rapido_icon.svg";
import Delete_Icon from "../../Icons/Delete_Icon.svg";
import Edit_Icon from "../../Icons/Edit_Icon.svg";
import Go_Icon from "../../Icons/Go_Icon.svg";
import Full_Screen_Icon from "../../Icons/Full_Screen_Icon.svg";
import Pause_Icon from "../../Icons/Pause_Icon.svg";
import Play_Icon from "../../Icons/Play_Icon.svg";
import Pomodoro_Icon from "../../Icons/Pomodoro_Icon.svg";
import UnFull_Screen_Icon from "../../Icons/UnFull_Screen_Icon.svg";
import Black_pencil from "../../Icons/Black_pencil.svg";
import Black_trash from "../../Icons/Black_trash.svg";

const IconComponents = {
  add_Icon: Add_Icon,
  arrow_Down_Icon: Arrow_Down_Icon,
  arrow_Right_Icon: Arrow_Right_Icon,
  calculo_rapido_Icon: Calculo_rapido_Icon,
  delete_Icon: Delete_Icon,
  edit_Icon: Edit_Icon,
  go_Icon: Go_Icon,
  full_Screen_Icon: Full_Screen_Icon,
  pause_Icon: Pause_Icon,
  play_Icon: Play_Icon,
  pomodoro_Icon: Pomodoro_Icon,
  unFull_Screen_Icon: UnFull_Screen_Icon,
  black_pencil: Black_pencil,
  black_trash: Black_trash,
};

const Icon = ({ name, size = 16, ...rest }) => {
  const MyIcon = IconComponents[name];
  const styles = {
    height: `${size}px`,
    width: `${size}px`,
  };

  return (
    <div className="Icon__mainContainer">
      <img src={MyIcon} alt="Icono" {...rest} style={styles} />
    </div>
  );
};

export default Icon;

import { useState } from "react";
import { Container, Image, Row } from "react-bootstrap";
import ModalSubjectLetter from "../modalSubjectLetter/ModalSubjectLetter";
import "./SubjectLetter.scss";

const SubjectLetter = ({ image, title, info, item }) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <ModalSubjectLetter
        item={item}
        showModal={showModal}
        setShow={setShowModal}
      />
      <Container
        className="container__subjectLetter"
        onClick={() => {
          setShowModal(true);
        }}
      >
        <Row>
          <Image src={require(`../../app/img/${image}.png`)} alt={title} />
        </Row>
        <Row>
          <h2>{title}</h2>
        </Row>
        <Row>
          <p>{info}</p>
        </Row>
      </Container>
    </>
  );
};

export default SubjectLetter;

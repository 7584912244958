import { Container } from "react-bootstrap";
import ChannelBar from "../../molecules/channelBar/ChannelBar";
import Footer from "../../molecules/footer/Footer";
import Header from "../../molecules/header/Header";
import ApplicationInfo from "../../organisms/applicationInfo/ApplicationInfo";
import BasicInformation from "../../organisms/basicInformation/BasicInformation";
import Benefits from "../../organisms/benefits/Benefits";
import KnowledgeSection from "../../organisms/knowledgeSection/KnowledgeSection";
import BasicLayout from "../layout/BasicLayout";
import "./Home.scss";

const Home = () => {
  return (
    <>
      <Header />
      <Container className="container__Home" id="container__Home_1" fluid>
        <BasicLayout>
          <BasicInformation />
        </BasicLayout>
        <BasicLayout>
          <KnowledgeSection />
        </BasicLayout>
      </Container>
      <ApplicationInfo />
      <Container className="container__Home" id="container__Home_2" fluid>
        <BasicLayout>
          <Benefits />
        </BasicLayout>
      </Container>
      <ChannelBar />
      <Footer />
    </>
  );
};

export default Home;

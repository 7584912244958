import { Col, Container, Row } from "react-bootstrap";
import SubjectLetter from "../../atoms/subjectLetter/SubjectLetter";
import "./KnowledgeSection.scss";

const KnowledgeSection = () => {
  const subject = [
    {
      imageLogo: "imagenIngles",
      title: "Física",
      info: "La Física tiene como objetivo analizar las caracterícas de la energía, el tiempo y la materia, y cómo estos elementos interactuan entre si.",
      imageApp: "imageSubjectsApp",
    },
    {
      imageLogo: "imagenGeometria",
      title: "Geometría",
      info: "La geometría es una rama de la matemática cuyo objetivo es el estudio de las propiedades y las medidas de una figura en un plano o en un espacio.",
      imageApp: "imageSubjectsApp",
    },
    {
      imageLogo: "imagenMatematicas",
      title: "Matemáticas",
      info: "La matemática es una ciencia deductiva dedicada al estudio de las propiedades de los entes abstractos y de sus relaciones. Esta ciencia se caracteriza por operar con números, símbolos, figuras geométricas, etc.",
      imageApp: "imageSubjectsApp",
    },
    {
      imageLogo: "imagenIngles",
      title: "Historia",
      info: "La Historia es la disciplina que se encarga de estudiar los acontecimientos más relevantes para el desarrollo de la humanidad. Esta información se recopila a través de documentos, registros o testimonios fidedignos que se transmiten de generación en generación.",
      imageApp: "imageSubjectsApp",
    },
    {
      imageLogo: "imagenBiologia",
      title: "Biología",
      info: "Ciencia que estudia la estructura de los seres vivos y de sus procesos vitales.",
      imageApp: "imageSubjectsApp",
    },
    {
      imageLogo: "imagenFisica",
      title: "Química",
      info: "Ciencia que estudia la composición y las propiedades de la materia y de las transformaciones que esta experimenta sin que se alteren los elementos que la forman",
      imageApp: "imageSubjectsApp",
    },
  ];
  return (
    <Container className="container__KnowledgeSection" fluid={true}>
      <Row>
        <h1>Amplia y mejora tus conocimientos en estas áreas:</h1>
      </Row>
      <Row className="container__SubjectLetters">
        {subject.map((el) => {
          return (
            <Col className="col-12 col-md-4 col-lg">
              <SubjectLetter
                image={el.imageLogo}
                title={el.title}
                info={el.info}
                item={el}
              />
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default KnowledgeSection;

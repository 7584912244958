import { Modal } from 'react-bootstrap'
import './ModalInteractiveMessage.css'
const ModalInteractiveMessage = ({
  showModal,
  setShow,
  message,
  functionToExecute
}) => {
  return (
    <Modal show={showModal} onHide={() => setShow(false)}>
      <div className='ModalInteractiveMessage'>
        <p>{message}</p>
        <div className='activebutons'>
          <button onClick={() => {functionToExecute(); setShow(false)}} className='accept Button'>
            Confirmar
          </button>
          <button onClick={() => setShow(false)} className='reject Button'>
            Rechazar
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ModalInteractiveMessage

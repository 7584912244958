import React, { useEffect, useState } from "react";
import Icon from "../../atoms/Icon/Icon";
import TitleSection from "../../atoms/TitleSection/TitleSection";
import CardTaskPomodoro from "../../atoms/cardTaskPomodoro/CardTaskPomodoro";
import BasicLayout from "../../templates/layout/BasicLayout";
import { useLocation, useHistory } from "react-router-dom";
import AddPomodoro from "../../molecules/addPomodoro/AddPomodoro";
import ScheduleTask from "../../molecules/ScheduleTask/ScheduleTask";
import GoingPomodoro from "../../molecules/goingPomodoro/GoingPomodoro";
import { GetAllPomodoro } from "../../utilities/pomodoro";
import { useSelector } from "react-redux";
import "./PomodoroPage.scss";

const PomodoroPage = () => {
  const query = new URLSearchParams(useLocation().search);
  const infoUser = useSelector((state) => state.infoUser);
  const history = useHistory();
  const [tasks, setTasks] = useState([]);
  const site = query.get("site");
  const id = query.get("id");

  const handleChangeAddPomodoro = () => {
    history.push(`?site=nuevoPomodoro`);
  };

  const handleGoSchedule = () => {
    history.push(`?site=Programadas`);
  };

  const handleGoComplete = () => {
    history.push(`?site=Completadas`);
  };

  const handleGoToDoPomodoro = (id) => {
    history.push(`?id=${id}`);
  };

  useEffect(() => {
    if (infoUser._id) {
      GetAllPomodoro(
        infoUser._id,
        site === "Completadas"
          ? "completed"
          : site === "Programadas"
          ? "scheduled"
          : "all",
        0
      )
        .then((response) => {
          if (response.status !== 404) {
            setTasks(["info"]);
          } else {
            setTasks([]);
          }
          console.log("data", response);
        })
        .catch((error) => {
          console.log("Error", error.status);
        });
    }
  }, [infoUser, site]);

  return (
    <div className="externalContainer__PomodoroPage">
      <BasicLayout>
        <div className="PomodoroPage__mainContainer">
          {site === "nuevoPomodoro" ? (
            <div className="container__addPomodoro">
              <AddPomodoro />
            </div>
          ) : site === "Programadas" ? (
            <div className="container__addPomodoro">
              <ScheduleTask
                items={tasks}
                handleChangeAddPomodoro={handleChangeAddPomodoro}
                state={"schedule"}
              />
            </div>
          ) : site === "Completadas" ? (
            <div className="container__addPomodoro">
              <ScheduleTask
                items={tasks}
                handleChangeAddPomodoro={handleChangeAddPomodoro}
                state={"completed"}
              />
            </div>
          ) : id !== null ? (
            <GoingPomodoro />
          ) : (
            <>
              <div className="container__pomodoroIcon">
                <Icon name="pomodoro_Icon" size={500} />
                <div className="container__addPomodoroIcon">
                  <button onClick={handleChangeAddPomodoro}>
                    <Icon name="add_Icon" size={90} />
                  </button>
                </div>
              </div>
              <div className="container__titleAndInfoPomodoro">
                <TitleSection
                  classname={"TitlePomodoro"}
                  colorTag={"#264F80"}
                  positionTag={"left"}
                  title={"Pomodoro"}
                />
                <div className="container__CardsTasks">
                  <CardTaskPomodoro
                    title="Tareas Programadas"
                    items={tasks}
                    titleEmpty="No tiene tareas programadas"
                    onClick={handleGoSchedule}
                  />
                  <CardTaskPomodoro
                    title="Tareas completadas"
                    items={tasks}
                    titleEmpty="No tienes tareas completadas. ¡Agrega una tarea nueva!"
                    onClick={handleGoComplete}
                  />
                </div>
                <button
                  className="button__Go"
                  onClick={() => {
                    handleGoToDoPomodoro(21);
                  }}
                >
                  <Icon name="go_Icon" size={90} />
                </button>
              </div>
            </>
          )}
        </div>
      </BasicLayout>
    </div>
  );
};

export default PomodoroPage;

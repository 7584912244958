import "./TitleSubject.scss";

const TitleSubject = ({ title, subtitles }) => {
  return (
    <div className="title">
      <div />
      <div>
        <h1>{title}</h1>
        {subtitles?.map((el, index) => {
          return <h2 key={`h2-n-${index}`}>{el}</h2>;
        })}
      </div>
    </div>
  );
};

export default TitleSubject;

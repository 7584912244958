import Icon from "../Icon/Icon";
import "./CardTaskSchedule.scss";

const CardTaskSchedule = (info, onDelete, state) => {
  // console.log("Info", info, state);
  return (
    <div
      className={`CardTaskSchedule__mainContainer ${
        info.state === "completed" ? "CardTaskCompleted__mainContainer" : ""
      }`}
    >
      {info.state !== "completed" && <div className="buttonState" />}
      <p>{info.info}</p>
      <button onClick={onDelete}>
        <Icon name="delete_Icon" size={30} />
      </button>
    </div>
  );
};

export default CardTaskSchedule;

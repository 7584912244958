import { Modal } from "react-bootstrap";
import "./ModalComingSoon.css";
const ModalComingSoon = ({ showModal, setShow }) => {
  return (
    <Modal show={showModal} onHide={() => setShow(false)}>
      <div className="modal__ModalComingSoon">
        <p>¡Próximamente!</p>
      </div>
    </Modal>
  );
};

export default ModalComingSoon;

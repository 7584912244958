import Icon from "../../atoms/Icon/Icon";
import TitleSection from "../../atoms/TitleSection/TitleSection";
import BasicLayout from "../../templates/layout/BasicLayout";
import "./QuickCalculation.scss";
import { useHistory } from "react-router-dom";


const QuickCalculation = () => {
  const history = useHistory();
  const handleQuickCalculationSection = () => {
    history.push("/quickCalculation");
  };

  return (
    <>
      <div className="container__QuickCalculation">
        <BasicLayout>
          <div className="container__Title">
            <TitleSection
              classname={"TitleQuickCalculation"}
              colorTag={"#009FAB"}
              positionTag={"right"}
              title={"Cálculo rápido"}
              accion={handleQuickCalculationSection}
            />
            <div
              className="contianer__calculo_rapido_Icon"
              onClick={handleQuickCalculationSection}
            >
              <Icon
                name="calculo_rapido_Icon"
                className="clase-para-estilos"
                size={250}
              />
            </div>
          </div>
        </BasicLayout>
      </div>{" "}
    </>
  );
};

export default QuickCalculation;

import React, { useEffect, useRef, useState } from "react";
import "./SitlenCarouselContent.scss";
//import useScreenWidth from "../../utilities/Hook/useScreenWidth";
//const breakpoint = 768;

const SitlenCarouselContent = ({ items }) => {
  const [positionActive, setPositionActive] = useState(0);
  const length = items.length;
  const refContainerBanner = useRef();
  //const vw = useScreenWidth();

  const handleChangeImage = (event, position) => {
    // console.log("Position", position);
    let auxPosition = position;
    if (position === length) {
      auxPosition = 0;
    } else if (position === -1) {
      auxPosition = length - 1;
    }
    const element = document.getElementById(`item-${auxPosition}`);
    element?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "center",
    });
    setPositionActive(auxPosition);
  };

  /*const onScroll = () => {
    if (refContainerBanner?.current) {
      //@ts-ignore
      const breakPoint = refContainerBanner?.current?.clientWidth;
      //@ts-ignore
      const leftScroll = refContainerBanner?.current?.scrollLeft;

      if (vw > breakpoint) {
        handleChangeImage("", Math.round(leftScroll / breakPoint));
      } else {
        handleChangeImage("", Math.round(leftScroll / vw));
      }
    }
  };*/

  useEffect(() => {
    handleChangeImage("", 0);
  }, [items]);

  return (
    <div>
      <div
        className="containerCarousel"
        ref={refContainerBanner}
        //onScroll={onScroll}
      >
        {items.map((el, index) => {
          return <div id={`item-${index}`}>{el}</div>;
        })}
      </div>
      <div className="containerControls">
        {items.map((el, index) => {
          return (
            <button
              className={positionActive === index ? "button-active" : ""}
              key={`button-content-${index}`}
              onClick={(e) => {
                handleChangeImage(e, index);
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default SitlenCarouselContent;

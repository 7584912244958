import { Container, Row } from "react-bootstrap";
import "./CardInfo.scss";

const CardInfo = ({ title, info }) => {
  return (
    <Container className="container__CardInfo" fluid>
      <Row>
        <h2>{title}</h2>
      </Row>
      <Row>
        <p className="p__CardInfo">{info}</p>
      </Row>
    </Container>
  );
};

export default CardInfo;

import Footer from "../../molecules/footer/Footer";
import HeaderProfile from "../../molecules/headerProfile/HeaderProfile";
import QuickCalculationPage from "../../organisms/quickCalculationPage/QuickCalculationPage";
import "./QuickCalculation.scss"

const QuickCalculation = () => {
  return (
    <>
      <HeaderProfile />
      <QuickCalculationPage />
      <Footer />
    </>
  );
};

export default QuickCalculation;
import "./TimePomodoro.scss";

const TimePomodoro = ({ values, setValues }) => {
  return (
    <div className="timePomodoro__MainContainer">
      {[...Array(15)].map((el, index) => {
        return (
          <button
            className={`buttonSelectTime ${
              values?.time === (index + 1) * 5 ? "buttonSelectedTime" : ""
            }`}
            onClick={() => {
              setValues("time", (index + 1) * 5);
            }}
          >
            {(index + 1) * 5} Minutos
          </button>
        );
      })}
    </div>
  );
};

export default TimePomodoro;

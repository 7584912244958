import axios from "axios";

export const GetChapterByID = async (id) => {
  return axios.post(
    "https://us-central1-sitlen.cloudfunctions.net/app/capitulos",
    {
      id: id,
    },
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
    }
  );
};

export const GetInfoTitle = async (topicId, chapterId, titleId) => {
  return axios.post(
    "https://us-central1-sitlen.cloudfunctions.net/app/slides",
    {
      temaId: topicId,
      capituloId: chapterId,
      tituloId: titleId,
    },
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
    }
  );
};

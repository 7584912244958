import Modal from "react-bootstrap/Modal";
import SubjectLetterInfo from "../subjectLetterInfo/SubjectLetterInfo";
import "./ModalSubjectLetter.css";

const ModalSubjectLetter = ({ item, showModal, setShow }) => {
  return (
    <Modal
      id="modal__ModalSubjectLetter"
      show={showModal}
      onHide={() => setShow(false)}
    >
      <SubjectLetterInfo item={item} />
    </Modal>
  );
};

export default ModalSubjectLetter;

import Footer from "../../molecules/footer/Footer";
import HeaderProfile from "../../molecules/headerProfile/HeaderProfile";
import PomodoroPage from "../../organisms/pomodoroPage/PomodoroPage";
import "./Pomodoro.scss";

const Pomodoro = () => {
  return (
    <>
      <HeaderProfile />
      <PomodoroPage />
      <Footer />
    </>
  );
};

export default Pomodoro;

import "./SessionsPomodoro.scss";

const SessionsPomodoro = ({ values, setValues }) => {
  return (
    <div className="sessionsPomodoro__MainContainer">
      {[...Array(6)].map((el, index) => {
        return (
          <button
            className={`buttonSession ${
              values?.sessions === index + 1 ? "buttonSessionSelected" : ""
            }`}
            onClick={() => {
              setValues("sessions", index + 1);
            }}
          >
            {index + 1}
          </button>
        );
      })}
    </div>
  );
};

export default SessionsPomodoro;

import "./ContainerVideo.scss";

const ContainerVideo = ({ item }) => {
  return (
    <div className="container__ContainerVideo">
      <div>
        <iframe
          src="https://www.youtube.com/embed/vW9LUqldeac"
          title="SitlenPresentation"
          controls="0"
          modestbranding
          style={{
            padding: "0px",
            borderRadius: "25px 25px 0px 0px",
            width: "100%",
            height: "634px",
          }}
        />
      </div>
      <div className="container__Name">
        <h3>Presentación Sitlen</h3>
      </div>
    </div>
  );
};

export default ContainerVideo;

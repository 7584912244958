import { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { BsGoogle } from "react-icons/bs"; //BsFacebook
import "./FormLoginAndRegister.scss";
import { useDispatch, useSelector } from "react-redux";
//import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { authentication } from "../../utilities/firebase/firebaseConfig";
import {
  //FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { actionLogin } from "../../store/login/actions";
import { ModalLoading } from "../../atoms/modalLoading/ModalLoading";

const FormLoginAndRegister = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isLogin = useSelector((state) => state.login);
  const [tab, setTab] = useState(0);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorLogin, setErrorLogin] = useState(false);

  const handleLogin = async (token) => {
    localStorage.setItem("Token", token);
    setLoading(true);
    console.log("Action Login");
    dispatch(actionLogin({ token: token }));
  };

  useEffect(() => {
    if (
      !isLogin.isAuthenticated &&
      isLogin.message === "No se pudo autenticar"
    ) {
      setLoading(false);
      setErrorLogin(true);
    }
  }, [isLogin]);

  /*const signInWithFacebook = () => {
    const provider = new FacebookAuthProvider();
    signInWithPopup(authentication, provider)
      .then((result) => {
        result.user.getIdToken().then((token) => {
          handleLogin(token);
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };*/

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(authentication, provider)
      .then((result) => {
        result.user.getIdToken().then((token) => {
          handleLogin(token);
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const singInWithEmailAndPassword = () => {
    signInWithEmailAndPassword(authentication, email, password)
      .then((result) => {
        handleLogin(result);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const RegisterWithEmailAndPassword = () => {
    createUserWithEmailAndPassword(authentication, email, password)
      .then((result) => {
        handleLogin(result);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      {loading && <ModalLoading />}
      <Container className="container__FormLoginAndRegister" fluid={true}>
        <Row className="mb-5">
          <Col
            style={{ paddingRight: "0px" }}
            className="container__buttons_register_type"
          >
            <Button
              className={`button__Tab${tab === 0 ? "Active" : "Disable"}`}
              onClick={() => setTab(0)}
            >
              Ingresar
            </Button>
          </Col>
          <Col
            style={{ paddingLeft: "0px" }}
            className="container__buttons_register_type"
          >
            <Button
              className={`button__Tab${tab === 1 ? "Active" : "Disable"}`}
              onClick={() => setTab(1)}
            >
              Registrarse
            </Button>
          </Col>
        </Row>
        {tab === 0 ? (
          <Container>
            <Row className="justify-content-center">
              <Col xs={10}>
                <Button
                  className="button__Social"
                  onClick={() => signInWithGoogle()}
                >
                  <BsGoogle />
                  <p>Inicia Sesión Con Google</p>
                </Button>
              </Col>
            </Row>
            {/*<Row className="justify-content-center">
              <Col xs={10}>
                <Button
                  className="button__Social"
                  onClick={() => signInWithFacebook()}
                >
                  <BsFacebook />
                  <p>Inicia Con Facebook</p>
                </Button>
              </Col>
        </Row>*/}
            <Row className="mt-5">
              <Col className="d-none d-md-block">
                <hr />
              </Col>
              <Col
                className="justify-content-center d-flex align-content-center"
                md="auto"
              >
                <p>o continuar con</p>
              </Col>
              <Col className="d-none d-md-block">
                <hr />
              </Col>
            </Row>
            <Row className="mt-5">
              <input
                placeholder="Email"
                className="input__formLoginAndRegister"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></input>
            </Row>
            <Row className="mt-4">
              <input
                placeholder="Contraseña"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="input__formLoginAndRegister"
                type="password"
              ></input>
            </Row>

            <Row className="mt-4">
              <Col>
                {errorLogin && (
                  <p className="p_incorrectUserOrPassword">
                    Usuario o contraseña incorrectos
                  </p>
                )}
              </Col>
              <Col className="d-flex justify-content-end">
                <a className="a__Links" href="/forgotPassword">
                  ¿No recuerdas tu contraseña?
                </a>
              </Col>
            </Row>
            <Row className="justify-content-center mt-5">
              <Col xs={10}>
                <Button
                  className="button_loginAndRegister"
                  onClick={singInWithEmailAndPassword}
                >
                  INGRESAR
                </Button>
              </Col>
            </Row>
            <Row className="mt-4">
              <p>
                Si aún no ingresaste,{" "}
                <u onClick={() => setTab(1)} className="a__Links">
                  Registrate
                </u>
              </p>
            </Row>
          </Container>
        ) : (
          <Container>
            <Row className="justify-content-center">
              <Col xs={10}>
                <Button
                  className="button__Social"
                  onClick={() => signInWithGoogle()}
                >
                  <BsGoogle />
                  <p>Registrate Con Google</p>
                </Button>
              </Col>
            </Row>
            {/*
            <Row className="justify-content-center">
              <Col xs={10}>
                <Button
                  className="button__Social"
                  onClick={() => signInWithFacebook()}
                  component={Link}
                  to="/Profile"
                >
                  <BsFacebook />
                  <p>Inicia Con Facebook</p>
                </Button>
              </Col>
            </Row>
            */}
            <Row className="mt-5">
              <Col className="d-none d-md-block">
                <hr />
              </Col>
              <Col className="justify-content-center d-flex" md="auto">
                <p>o continuar con</p>
              </Col>
              <Col className="d-none d-md-block">
                <hr />
              </Col>
            </Row>
            <Row className="mt-4">
              <input
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="input__formLoginAndRegister"
              ></input>
            </Row>
            <Row className="mt-4">
              <input
                placeholder="Contraseña"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="input__formLoginAndRegister"
              ></input>
            </Row>
            {/*<Row className="mt-4">
              <p>
                Al registrarte, aceptas los
                <a className="a__Links">términos y condiciones</a>
              </p>
        </Row>*/}
            <Row className="justify-content-center mt-5">
              <Col xs={10}>
                <Button
                  className="button_loginAndRegister"
                  onClick={RegisterWithEmailAndPassword}
                >
                  REGISTRARSE
                </Button>
              </Col>
            </Row>
            <Row className="mt-4">
              <p>
                Si ya tienes cuenta,{" "}
                <u className="a__Links" onClick={() => setTab(0)}>
                  Ingresar
                </u>
              </p>
            </Row>
          </Container>
        )}
      </Container>
    </>
  );
};

export default FormLoginAndRegister;

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import "./BenefitsCard.scss";

const BenefitsCard = ({ title, info }) => {
  return (
    <Container className="container__BenefitsCard">
      <Row>
        <h6>{title}</h6>
      </Row>
      <Row>
        <p className="p__BenefitsCard">{info}</p>
      </Row>
    </Container>
  );
};

export default BenefitsCard;

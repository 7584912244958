import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCduxKG7RztuNztW83T-JrNo1gy6rPqUUY",
  authDomain: "sitlen.firebaseapp.com",
  databaseURL: "https://sitlen-default-rtdb.firebaseio.com",
  projectId: "sitlen",
  storageBucket: "sitlen.appspot.com",
  messagingSenderId: "1010255817440",
  appId: "1:1010255817440:web:23e610a66aab9fb3b6be85",
  measurementId: "G-LNLM4SEBTN",
};

const app = initializeApp(firebaseConfig);
export const authentication = getAuth(app);

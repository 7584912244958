const Input = ({
  nameInpup,
  type,
  value,
  onChange,
  classNameTitle,
  classNameInput,
}) => {
  return (
    <>
      <div className={classNameTitle}>{nameInpup}</div>
      <input
        type={type}
        value={value}
        onChange={onChange}
        className={classNameInput}
      />
    </>
  )
}

const InputsList = ({ inputs }) => {
  return (
    <>
      {inputs.map((input, index) => (
        <Input key={index} {...input} />
      ))}
    </>
  )
}

export default InputsList

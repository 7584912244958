import { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import ContainerListChapter from "../../atoms/containerListChapter/ContainerListChapter";
import { ModalLoading, ImageLoading } from "../../atoms/modalLoading/ModalLoading";
import TitleSubject from "../../atoms/titleSubject/TitleSubject";
import Footer from "../../molecules/footer/Footer";
import HeaderProfile from "../../molecules/headerProfile/HeaderProfile";
import { GetAllTopics, topcisMapImageUrl } from "../../utilities/tipics";
import { useLocation, useHistory } from "react-router-dom";
import { GetChapterByID, GetInfoTitle } from "../../utilities/chapters";
import { Image } from "react-bootstrap";
import "./Signature.scss";
import SitlenCarouselContent from "../../atoms/sitlenCarouselContent/SitlenCarouselContent";

const Signature = () => {
  const { id } = useParams();
  const [subjects, setSubjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currerntSubject, setCurrentSubject] = useState({});
  const query = new URLSearchParams(useLocation().search);
  const [chapters, setChapters] = useState([]);
  const [infoTitle, setInfoTitle] = useState([]);
  const idChapter = query.get("capitulo");
  const idTitle = query.get("titulo");
  const [subjectCode, setSubjectCode] = useState("");
  const [chapterCode, setChapterCode] = useState("");
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    GetAllTopics()
      .then((data) => {
        setLoading(false);
        setSubjects(data.data.Temas);
      })
      .catch((error) => {
        console.error("Error en get", error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const element = topcisMapImageUrl.filter((el) => {
      return el.url === id;
    })[0];
    const auxCurrentSubject = subjects.filter((el) => {
      return el.Nombre === element.title;
    })[0];
    setSubjectCode(auxCurrentSubject);
    setCurrentSubject(auxCurrentSubject);
  }, [subjects, id]);

  useEffect(() => {
    if (idChapter && currerntSubject?.Capitulos) {
      setLoading(true);
      const idChapterCode = currerntSubject?.Capitulos?.filter(
        (el) => el.Nombre === idChapter
      )[0].Id;
      setChapterCode(idChapterCode);
      GetChapterByID(currerntSubject.id)
        .then((data) => {
          setLoading(false);
          console.log(
            "Data",
            data.data.Capitulos.filter((el) => {
              return el.Nombre === idChapter;
            })
          );
          setChapters(
            data.data.Capitulos.filter((el) => {
              return el.Nombre === idChapter;
            })
          );
        })
        .catch((error) => {
          console.error("Error en get", error);
          setLoading(false);
        });
    }
  }, [idChapter, currerntSubject]);

  useEffect(() => {
    if (idTitle && chapters.length > 0) {
      setLoading(true);
      const idTitleCode = chapters[0].Titulos.filter(
        (el) => el.Nombre === idTitle
      )[0].Id;
      GetInfoTitle(
        parseInt(subjectCode.id),
        parseInt(chapterCode),
        parseInt(idTitleCode)
      )
        .then((data) => {
          setLoading(false);
          setInfoTitle(data.data.Informacion);
        })
        .catch((error) => {
          console.error("Error en get", error);
          setLoading(false);
        });
    }
  }, [idTitle, chapters, chapterCode, subjectCode]);

  const handleComeBack = () => {
    setInfoTitle([]);
    history.push(`?${location.search.split("?")[1].split("&")[0]}`);
  };

  useEffect(() => {
    if (!idTitle) setInfoTitle([]);
  }, [idTitle]);

  return loading ? (
    <ModalLoading />
  ) : (
    <>
      <HeaderProfile />
      <div className="externalContainerSignature">
        <div className="mainContainerSignature">
          <TitleSubject title={currerntSubject?.Nombre} />
          {idChapter && (
            <div className="container__BreadCrumbs">
              <h3>{`${idChapter ? "  >  " : ""} ${idChapter || ""} ${
                idTitle ? "  >  " : ""
              } ${idTitle || ""}`}</h3>
            </div>
          )}
          {currerntSubject && infoTitle.length === 0 && (
            <ContainerListChapter
              items={currerntSubject?.Capitulos}
              comeBack={false}
              nameParam="capitulo"
              hasTitles={idChapter && chapters?.length > 0}
              titles={chapters[0]?.Titulos}
            />
          )}
          {infoTitle.length > 0 && (
            <div className="external__container__carouselContent">
              <SitlenCarouselContent
                items={infoTitle.map((el, index) => {
                  return (
                    <div
                      className="containerContents"
                      key={`container-info-${index}`}
                    >
                      {el.Imagen && (
                        <ImageLoading
                          src={el.Imagen}
                          fallback={require("../../app/img/logo_pencil_primary.png")}
                          alt={`image-${id}-${idChapter}-${idTitle}-${0}`}
                        />
                      )}
                      <p>{el.Texto}</p>
                    </div>
                  );
                })}
              />
              <div className="continer__comebackButton">
                <button onClick={handleComeBack}>
                  <span>{`<`}</span>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Signature;
